import { Component, Input, OnChanges } from '@angular/core';
import { environment } from './../../environments/environment';
import { ArtistService } from "../services/artist.service";
import { Artist } from "../model";
import { Router } from '@angular/router';

@Component({
  selector: 'party-link',
  templateUrl: './party-link.component.html',
  styleUrls: ['./party-link.component.css']
})
export class PartyLinkComponent implements OnChanges {

  @Input() party: Artist|null = null;
  isLink = true;

  authorizedDemoIsni: Array<string> = ['0000000055472858', '0000000462998427', '0000000078391751', '0000000081527349', '000000009132190X', '0000000114936588', '0000000120519801', '0000000121195925', '000000012144707X', '0000000460095849']

  identifier = {
    type: "",
    value: ""
  };
  text = "";

  constructor(private artistService: ArtistService, private router: Router) { }

  buildLink(idType: string, id: string): void{
    this.identifier.type = idType;
    this.identifier.value = this.artistService.formatIdRaw(idType, id);
    this.isLink = environment.demo ?  this.identifier.type === "isni" && this.authorizedDemoIsni.includes(this.identifier.value) : true;
  }

  ngOnChanges(): void {
    if (this.party && this.party.ids) {
      this.text = this.party.name;
      const quansicId = this.party.ids.quansicId;
      this.identifier.type = "quansicId";
      this.identifier.value = quansicId;
    }
  }

  // navigate() {
  //   if(this.identifier.type === 'quansicId')
  //     this.router.navigate(['app-party', this.identifier.value])
  //   else this.router.navigate(['/app-party', this.identifier.type, this.identifier.value])
  // }
}
