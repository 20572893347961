import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot} from "@angular/router";
import {LoginService} from "./login.service";
import {Observable, of} from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(protected router: Router, protected loginService: LoginService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const key = route.queryParams['key'];
    const hasKey = key !== null && key !== undefined;
    if (!hasKey && !this.loginService.isAuthenticated()) {
      this.router.navigateByUrl('/app-login');
      return false;
    }
    return true;
  }
}
