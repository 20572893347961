import { HttpErrorResponse } from "@angular/common/http";
import { ApiError } from "src/app/model";

export const interpretError = (error: HttpErrorResponse): string => {
  console.log("interpretError", error)
  if(error === null || error === undefined) return ApiError.SERVER_ERROR;
  switch(error.status) {
    case 401:
    case 403:
      return ApiError.UNAUTHORIZED
    case 404:
      return ApiError.NOT_FOUND
    case 400:
      return ApiError.INVALID_ID
    case 500:
    case 501:
    case 502:
        return ApiError.SERVER_ERROR
    default:
      return ApiError.UNKNOWN
  }
}
