import { TableWorksComponent } from '../shared/components/table-works/table-works.component';
import { TableReleasesComponent } from '../shared/components/table-releases/table-releases.component';
import { Component, OnChanges, ViewChild, AfterViewInit, inject } from '@angular/core';
import { ApiError, Artist, Recording, Release, Work} from "../model";
import { ExportService} from "../services/export.service";
import { ImageService } from '../services/image.service';
import { combineLatest, map, take, takeUntil, tap} from "rxjs";
import { ActivatedRoute, Router} from "@angular/router";
import { TabsController} from "../_common/tabs.controller";
import { TableRecordingsComponent } from '../shared/components/table-recordings/table-recordings.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogRowsExportComponent, DialogRowsExportData } from '../dialog-rows-export/dialog-rows-export.component';
import { CrtcService } from '../crtc/services/crtc.service';
import { ExportCRTCService } from '../crtc/services/export-crtc.services';
import { Store } from '@ngrx/store';
import { ArtistState } from '../shared/store/artist/artist.reducer';
import { workQuery } from '../shared/store/work/work.selectors';
import { WorkState } from '../shared/store/work/work.reducer';
import { releaseQuery } from '../shared/store/release/release.selectors';
import { recordingQuery } from '../shared/store/recording/recording.selectors';
import { ReleaseState } from '../shared/store/release/release.reducer';
import { artistActions } from '../shared/store/artist/artist.actions';
import { artistQuery } from '../shared/store/artist/artist.selectors';
import { DialogArtistExportComponent, DialogArtistExportData } from '../dialog-artist-export/dialog-artist-export.component';
import { TitleService } from '../shared/data/services/title.service';
import { DialogUtilsService } from '../shared/data/services/dialog-utils.service';

declare var toastr:any;

@Component({
  selector: 'app-artist',
  templateUrl: './app-artist.component.html',
  styleUrls: ['./app-artist.component.scss']
})
export class AppArtistComponent extends TabsController implements AfterViewInit {
  storeArtist = inject(Store<ArtistState>)
  storeWork = inject(Store<WorkState>)
  storeRelease = inject(Store<ReleaseState>)
  storeRecording = inject(Store<WorkState>)
  titleService = inject(TitleService)
  dialogUtilsService = inject(DialogUtilsService)
  artist!: Artist;

  @ViewChild(TableReleasesComponent) tableReleasesComponent!: TableReleasesComponent;
  @ViewChild(TableRecordingsComponent) tableRecordingsComponent!: TableRecordingsComponent;
  @ViewChild(TableWorksComponent) tableWorksComponent!: TableWorksComponent;

  exportCRTCService = inject(ExportCRTCService);

  toasterOptions: any = {
    "preventDuplicates": true,
    "positionClass": "toast-top-center",
    "timeOut": "2000",
  }

  ApiError = ApiError;

  searchParams$ = this.route.params.pipe(
    map(params =>
      params['quansicId']
      ? ({quansicId: params['quansicId']})
      : ({idType: params['idType'], id: params['id']})
    )
  )

  artist$ = this.storeArtist.select(artistQuery.selectArtist).pipe(
    takeUntil(this.destroy$),
    tap(artist => { if(artist !== null) this.artist = artist}),
    tap(artist => this.titleService.changeBrowserTitle('party', artist)),
  );
  artistStatus$ = this.storeArtist.select(artistQuery.selectStatus);
  artistError$ = this.storeArtist.select(artistQuery.selectError);

  works$ = this.storeWork.select(workQuery.selectWorks);
  worksStatus$ = this.storeWork.select(workQuery.selectStatus);

  releases$ = this.storeRelease.select(releaseQuery.selectReleases);
  releasesStatus$ = this.storeRelease.select(releaseQuery.selectStatus);

  recordings$ = this.storeRecording.select(recordingQuery.selectRecordings);
  recordingsStatus$ = this.storeRecording.select(recordingQuery.selectStatus);

  nameVariants$ = this.storeArtist.select(artistQuery.selectNameVariants)
  relationships$ = this.storeArtist.select(artistQuery.selectRelationships);


  constructor(
    private exportService: ExportService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public imageService: ImageService,
    public crtcService: CrtcService) {
    super(router, route);
  }

 

  ngAfterViewInit(){
    this.route.params.pipe(
      takeUntil(this.destroy$),
      tap(params => {
        const quansicId = params['quansicId'];
        if(quansicId)
         return this.storeArtist.dispatch(artistActions.getartistbyquansicid({quansicId}));
      }),
      tap(params => {
        const idType = params['idType'];
        const id = params['id'];
        if(idType && id)
          return this.storeArtist.dispatch(artistActions.getartistsbyid({idType, id}));
      }),
    ).subscribe()

   this.readActiveTab();
  }



  displaySuccessLockEventMessage(): void{
    toastr.options = this.toasterOptions;
    toastr.info(`The artist ${this.artist.name} has been locked`);
  }
  displaySuccessUnlockEventMessage(): void{
    toastr.options = this.toasterOptions;
    toastr.info(`The artist ${this.artist.name} has been unlocked`);
  }
  displayErrorEventMessage(error: string): void{
    toastr.options = this.toasterOptions;
    toastr.error(`The artist ${this.artist.name} lock/unlock attempt has failed [${error}]`);
  }

  canExport(): boolean {
    return this.tabActive != null  && this.tabActive >= 1 && this.tabActive <= 3;
  }

  exportArtist(): void {
    let exportFnExcel
    let exportFnJson
    if(this.crtcService.isCrtcUser()) {
      exportFnExcel = this.exportCRTCService.exportPartyAsExcel.bind(this.exportCRTCService)
      exportFnJson = this.exportCRTCService.exportPartyAsJson.bind(this.exportCRTCService)
    } else {
      exportFnExcel = this.exportService.exportPartyAsExcel
      exportFnJson = this.exportService.exportPartyAsJson
    }
    combineLatest([ this.nameVariants$, this.relationships$, this.releases$, this.recordings$, this.works$ ])
    .pipe(take(1))
    .subscribe(([nameVariants, relationships, releases, recordings, works]) => {
      this.dialog.open(DialogArtistExportComponent, {
        panelClass: "dialog",
        data: {
          artist: this.artist,
          releases, recordings, works, nameVariants, relationships,
          exportFn: {
            excel: exportFnExcel,
            json: exportFnJson,
          }} as DialogArtistExportData,
      });
    });
  }

  openExportRowsDialog() {
    let rows: Release[]|Recording[]|Work[]|null = [];
    let exportFn = {};
    let exportType: string|null = null;

    switch(this.tabActive) {
      case 1:
        exportType = "Release";
        rows = this.tableReleasesComponent.dataSource.filteredData.map(r => r as Release)
        exportFn = {
          excel: this.exportService.exportReleasesAsExcel,
          csv:  this.exportService.exportReleasesAsCSV,
        }
        break;
      case 2:
        exportType = "Recording";
        rows = this.tableRecordingsComponent.dataSource.filteredData.map(r => r as Recording)
        exportFn = {
          excel: this.exportService.exportRecordingsAsExcel,
          csv: this.exportService.exportRecordingsAsCSV
        }
        break;
      case 3:
        exportType = "Work";
        rows = this.tableWorksComponent.dataSource.filteredData.map(w => w as Work)
        exportFn = {
          excel: this.exportService.exportWorksAsExcel,
          csv:  this.exportService.exportWorksAsCSV
        }
        break;
    }
    this.dialog.open(DialogRowsExportComponent, {
      panelClass: "dialog",
      data: {
        artist: this.artist,
        entityName: this.artist.name,
        entityId: this.artist.ids.quansicId,
        exportType, rows, exportFn} as DialogRowsExportData,
    });
  }

  openFeedbackDialog() {
    this.dialogUtilsService.openFeedbackDialog();
  }
}
