<ng-container *ngIf="status$|async as status">
    <ng-container *ngIf="relationships$|async as relationships">
      <div class="relationships" *ngIf="relationships.aka.length > 0 || status == 'loading'">
        <div class="title">Also Known As:</div>
        <div class="grid grid-four-col">
          <ng-container *ngIf="status === 'loading'">
            <skeleton shape="small"></skeleton>
            <skeleton shape="small"></skeleton>
          </ng-container>
          <ng-container *ngFor="let rel of relationships.aka | sortBy:'asc':'name'">
            <party-link *ngIf="rel !== null" [party]="rel"></party-link>
          </ng-container>
        </div>
        <hr class="grid-col-span-2"/>
      </div>
      <div class="relationships" *ngIf="relationships.isMemberOf.length > 0">
        <div class="title">Is Member Of:</div>
        <div class="grid grid-four-col" >
          <ng-container *ngFor="let rel of relationships.isMemberOf | sortBy:'asc':'name'">
            <party-link [party]="rel"></party-link>
          </ng-container>
        </div>
        <hr class="grid-col-span-2"/>
      </div>
      <div class="relationships" *ngIf="relationships.hasMember.length > 0">
        <div class="title">Members:</div>
        <div class="grid grid-four-col">
          <party-link [party]="rel" *ngFor="let rel of relationships.hasMember | sortBy:'asc':'name'"></party-link>
        </div>
      </div>
    </ng-container>
</ng-container>


