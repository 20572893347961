import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const authActions = createActionGroup({
  source: 'Auth',
  events: {
    verifySession: emptyProps(),
    verifysessionsuccess: emptyProps(),
    verifysessionfailure: props<{error: string}>(),
  }
})
