import { AfterViewInit, Component, Input, OnInit, inject } from "@angular/core";
import { Observable, filter, tap } from "rxjs";
import { CrtcMaiplScore, CrtcSubCategory } from "../../services/crtc-model";
import { CrtcService } from "../../services/crtc.service";
import { CandidateChangeRequest, InlineEditService } from "src/app/services/inline-edit.service";
import { Recording, XCrtcProperties } from "src/app/model";
import { FormControl, FormGroup } from "@angular/forms";

enum CrtcProperties {
    LANGUAGE = "language",
    SUB_CATEGORY = "subCategory",
    IS_EMERGING = "isEmerging",
}

@Component({
    selector: 'maipl-properties-edit',
    templateUrl: './maipl-properties-edit.component.html',
    styleUrls: ['./maipl-properties-edit.component.scss'],
})
export class MaiplPropertiesEditComponent implements AfterViewInit{
    public inlineEditService = inject(InlineEditService)
    private crtcService = inject(CrtcService)

    @Input() recording!: Recording;

    formGroup = new FormGroup({
        language: new FormControl<string|null>(null),
        isEmerging: new FormControl<boolean|null>(null),
        subCategory: new FormControl<string|null>(null),
    })

    originalValue!: XCrtcProperties

    languageList = this.crtcService.getIso6392Languages()
    categoriesList = this.crtcService.getCrtcCategories();
    subCategoriesList = this.crtcService.getCrtcCategories().reduce((acc, category) => {
        if (category.subCategories) {
            acc = acc.concat(category.subCategories)
        }
        return acc
    }, [] as CrtcSubCategory[])

    ngAfterViewInit() {       
        this.setInitialValues();
        this.listenLanguage(); 
        this.listenSubCategory();       
        this.listenIsEmerging();
    }

    private setInitialValues() {
        this.originalValue = {...this.recording.crtcPropertiesStatus};

        const isEmerging: boolean|null = 
            this.recording.crtcPropertiesStatus.isEmerging === undefined 
            ? null 
            : this.recording.crtcPropertiesStatus.isEmerging;

        this.formGroup.patchValue({
            language: this.recording.crtcPropertiesStatus.language || null,
            subCategory: this.recording.crtcPropertiesStatus.subCategory || null,
            isEmerging: isEmerging
        }, {emitEvent: false})

        //If false, cannot be set to true
        if(this.originalValue.isEmerging === false) {
            this.formGroup.get('isEmerging')?.disable();
        }
    }

    private sendChangeRequest(originalValue: string|boolean|null, newValue: string|null, property: CrtcProperties, graphValue: any) {
        const changeCandidate: CandidateChangeRequest = {
          entityId: this.recording.id,
          entityType: this.recording.entityType,
          entityName: this.recording.title,
          displayId: this.recording.id,
          property: property,
          originalValue: originalValue,
          newValue: newValue,
          ySources: this.recording.ySources
        };
        this.inlineEditService.pushChangeRequestCandidate(changeCandidate, graphValue);
    }

    listenLanguage() {
        this.formGroup.get('language')?.valueChanges.pipe(
            tap(languageCode => 
                this.sendChangeRequest(this.originalValue.language, languageCode, CrtcProperties.LANGUAGE, this.recording.crtcPropertiesStatus.language))
        ).subscribe()
    }
    
    listenSubCategory() {
        this.formGroup.get('subCategory')?.valueChanges.pipe(
            tap(subCategoryId => this.sendChangeRequest(this.originalValue.subCategory, subCategoryId, CrtcProperties.SUB_CATEGORY, this.recording.crtcPropertiesStatus.subCategory))
        ).subscribe()
    }
    
    listenIsEmerging() {
        this.formGroup.get('isEmerging')?.valueChanges.pipe(
            tap(isEmerging => {
                if(isEmerging !== null) this.sendChangeRequest(this.originalValue.isEmerging, JSON.stringify(isEmerging), CrtcProperties.IS_EMERGING, this.recording.crtcPropertiesStatus.isEmerging)
            })
        ).subscribe()
    }

    setIsEmerging(isEmerging: boolean) {
        if(this.formGroup.get('isEmerging')?.disabled) return;
        this.formGroup.get('isEmerging')?.patchValue(isEmerging);
    }

    isEmergingNotSet() {
        return this.formGroup.get('isEmerging')?.value === null && this.formGroup.get('isEmerging')?.enabled;
    }
}