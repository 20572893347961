<div class='mat-typography'>
  <div style="display:flex">
    <h1 mat-dialog-title style="flex-grow: 1">{{title}}</h1>
    <button mat-dialog-close mat-flat-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      <div style="flex-basis: 70vh;flex-grow: 1;"><img [src]='coverData.cover' width='auto' height='100%' alt="Cover" style="max-width: 100%"/></div>
      <div *ngIf='licenseInfo !== null'>
        <div style="margin-top: 10px">
          <a [href]="licenseInfo.link" target="_blank">{{licenseInfo.label}}</a>
        </div>
        <div *ngIf="licenseInfo.attribution !== null">{{licenseInfo.attribution}}</div>
      </div>
      <div style="margin-top: 1em" *ngIf="isWikimediaImage(coverData.originalUrl)">
        <a class="link" [href]="coverData.originalUrl" target="_blank" >
          Open on Wikimedia Commons to list authors
        </a>
      </div>
    </div>
  </div>
</div>
