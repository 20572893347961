import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: 'skeleton-artist',
  templateUrl: './skeleton-artist.component.html',
  styleUrls: ['./skeleton-artist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonArtistComponent {

  @Input() quansicId!: string;
}
