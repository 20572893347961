<div class="background"></div>

<div class="container login two-col">
  <div class="left-block">
    <h1 class="hero">Data Explorer</h1>
    <h2>by <strong>QUANSIC</strong></h2>
  </div>
  <div class="right-block">
    <form [formGroup]="form">
      <div class="loginForm">
        <label style="flex-shrink: 1">Api Key</label>
        <mat-form-field appearance="fill" floatLabel="never" style="flex-grow: 1">
          <input matInput formControlName="apiKey">
          <mat-error *ngIf="isApiKeyEmpty()">The api key must be provided</mat-error>
        </mat-form-field>

        <mat-spinner *ngIf="queryInProgress" diameter="40" color="accent" style="margin-left: 80px"></mat-spinner>
        <button mat-button mat-raised-button *ngIf="!queryInProgress" (click)="login()" [autofocus]="true" class="rounded-button" color="accent">Login</button>
        <mat-error *ngIf="authFailed">Authentication Failed</mat-error>

        <a class="link" routerLink="/login" style="grid-column: span 2;">Back to login form</a>
      </div>
    </form>

  </div>

  <div></div>
  <div>

  </div>
</div>
