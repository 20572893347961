import { ProgressService } from './../../services/progress.service';
import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LockService } from '../../services/lock.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ArtistLockAction, ArtistLockStatus } from '../../model';


@Component({
  selector: 'dialog-lock',
  templateUrl: './dialog-lock.component.html',
  styleUrls: ['./dialog-lock.component.scss']
})
export class DialogLockComponent {
  form: FormGroup = new FormGroup({
    comment: new FormControl('', Validators.required),
  });
  httpError: string|null = null;
  ArtistLockAction = ArtistLockAction;
  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<DialogLockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private progressService: ProgressService,
    private lockService: LockService ) {

  }


  lock(): void {
    if(!this.form.invalid) {
      const comment = this.form.get('comment')?.value;
      this.progressService.progressTopic.next({queryInProgress: true})
      this.lockService.lock(this.data?.artist?.id, comment).subscribe({
        next: (result) => {
          this.progressService.progressTopic.next({queryInProgress: false})
          this.data.successCallback?.call(this, ArtistLockStatus.Locked);
          this.dialogRef.close();
        },
        error: (err) => {
          console.error('HTTP Error', err);
          this.httpError = err.error?.message || 'An error occurred';
          this.progressService.progressTopic.next({queryInProgress: false})
          this.data.successCallback?.call(this, err)
        }
      })
    }
  }

  unlock(): void {
    if(!this.form.invalid) {
      const comment = this.form.get('comment')?.value;
      this.progressService.progressTopic.next({queryInProgress: true})
      this.lockService.unlock(this.data?.artist?.id, comment).subscribe(
        res => {
          this.progressService.progressTopic.next({queryInProgress: false})
          this.data.successCallback?.call(this, ArtistLockStatus.Unlocked);
          this.dialogRef.close();
        },
        err => {
          console.error('HTTP Error', err);
          this.httpError = err.error?.message || 'An error occurred';
          this.progressService.progressTopic.next({queryInProgress: false})
          this.data.successCallback?.call(this, err)
        }
      )
    }
  }
}
