import {Component, OnInit} from '@angular/core';
import { LoginService } from '@githubquansic/web-auth/ng';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {EmailService} from "../../services/email.service";
import {LogService} from "../../services/log.service";

@Component({
  selector: 'app-login',
  templateUrl: './app-login.component.html',
  styleUrls: ['./app-login.component.scss']
})
export class AppLoginComponent {
  form = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required)
  });
  authFailed = false;
  submitted = false;
  queryInProgress = false;
  hide = true;

  constructor(
    private loginService: LoginService,
    private emailService: EmailService,
    private router: Router,
    private logService: LogService) {}

  isEmailEmpty(): boolean {
    return this.form.controls['email'].errors?.['required'] &&
           this.form.controls['email'].value === "" &&
           this.submitted;
  }
  isEmailInvalid(): boolean {
    return this.form.controls['email'].errors?.['email'] &&
           this.submitted;
  }

  isPasswordEmpty(): boolean {
    return this.form.controls['password'].errors?.['required'] &&
           this.form.controls['password'].value === "" &&
           this.submitted;
  }

  isAuthenticationFailed(): boolean {
    return this.form.errors?.['loginFailed'] &&this.submitted;
  }

  login(): void {
    this.submitted = true;
    if (!this.form.controls['email'].errors) {
      this.queryInProgress = true;
      const email:string|null = this.form.get('email')!.value;
      const password:string|null = this.form.get('password')!.value;
      if(email && password != null) {
        this.loginService.loginWithPassword(email, password).subscribe({
          next: () => {
            this.logService.recordLogin();
            this.queryInProgress = false;
            this.router.navigate(['/app-home']);
          },
          error: (err) => {
            console.log("loginService.loginWithPassword fail", err);
            this.queryInProgress = false;
            this.form.setErrors({loginFailed: true});
            this.authFailed = true;
          }
        });
      }
    }
  }

  logout(): void {
    this.loginService.logout();
  }
}
