import { ApiError, RecordingOfWork } from './../model';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsController } from './../_common/tabs.controller';
import { Component, OnInit, ViewChild, inject} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Artist, Recording, Work} from "../model";
import { TableRecordingsComponent } from '../shared/components/table-recordings/table-recordings.component';
import { MatDialog } from '@angular/material/dialog';
import { ExportService } from '../services/export.service';
import { Store } from '@ngrx/store';
import { workActions } from '../shared/store/work/work.actions';
import { IdentifiersService } from '../shared/data/services/identifiers.service';
import { combineLatest, map, take, takeUntil, tap } from 'rxjs';
import { workQuery } from '../shared/store/work/work.selectors';
import { TitleService } from '../shared/data/services/title.service';
import { DialogUtilsService } from '../shared/data/services/dialog-utils.service';
import { FeedbackContext } from '../dialog-feedback/dialog-feedback.component';

@Component({
  selector: 'app-work',
  templateUrl: './app-work.component.html',
  styleUrls: ['./app-work.component.css']
})
export class AppWorkComponent  extends TabsController implements OnInit {
  store = inject(Store)
  exportService = inject(ExportService)
  identifiersService = inject(IdentifiersService);
  titleService = inject(TitleService);
  dialogUtilsService = inject(DialogUtilsService)

  @ViewChild(MatTabGroup) matTabGroup!: MatTabGroup;
  // @ViewChild(TableRecordingsComponent) tableRecordingsComponent!: TableRecordingsComponent;

  constructor(private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute) {
    super(router, route);
  }

  ApiError = ApiError;

  work$ = this.store.select(workQuery.selectWork).pipe(
    tap(work => this.titleService.changeBrowserTitle('work', work)),
  );
  recordings$ = this.store.select(workQuery.selectRecordings).pipe(
    map(recordings => {
      if(recordings !== null) return this.enrichMainArtists(recordings)
      else return null
    })
  )
  workStatus$ = this.store.select(workQuery.selectStatus);
  workError$ = this.store.select(workQuery.selectError);
  searchParams$ = this.route.params.pipe(
    takeUntil(this.destroy$),
    map(params => (params['id']))
  )

  ngOnInit(): void {
    this.readActiveTabFromParam(this.matTabGroup);
    this.searchParams$.subscribe({
      next: id => {
        if(this.identifiersService.isBowi(id)) this.store.dispatch(workActions.getworkbybowi({bowi: id}));
        if(this.identifiersService.isIswc(id)) this.store.dispatch(workActions.getworkbyiswc({iswc: id}));
      }
    })

    this.readActiveTab();
  }

  enrichMainArtists(recordings: RecordingOfWork[]): RecordingOfWork[] {
    return recordings.map((recording: Recording) => ({
        ...recording,
        mainArtists: recording.contributors?.filter((contrib:Artist) => contrib.contributorType === "MainArtist")
      })
    )
  }

  exportWork( work: Work) {
    combineLatest([
      this.recordings$,
      this.work$
    ]).pipe(
      take(1),
    ).subscribe({
      next: ([recordings, work]) => {
        if(work !== null && recordings !== null) {
          this.exportService.exportWorkAsExcel(work, recordings, work.contributors || [], work.title)
        }
      }
    });
  }

  openFeedbackDialog() {
    this.dialogUtilsService.openFeedbackDialog();
  }
}
