import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-large-recs-message',
  template: `
    <div class="label" matSnackBarLabel>
      <div class="warning">⚠️</div>
      <div class="text">
        This artist has a large number of recordings ({{data.recordingsCount}}). <br/>
        It may take a moment to load all the recordings.
      </div>
    </div>
    <span matSnackBarActions>
      <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">dismiss</button>
    </span>
  `,
  styleUrls: ['./large-recs-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LargeRecsMessageComponent {
  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {recordingsCount: number}) { }

}
