<div class="mat-typography">
  <h1 mat-dialog-title>Contact Us</h1>
  <div mat-dialog-content>
    <div *ngIf="!contactSent" class="contact">
      <h4 *ngIf="topic != 'registrationKey'">Whenever you face a problem or have ideas about Data Explorer's improvements, we're very much looking forward to your feedbacks!</h4>
      <form [formGroup]="contactForm">
        <div class="two-col-grid">
          <label>Company<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="company" aria-describedby="company" formControlName="company"
              [ngClass]="{ 'is-invalid': isCompanyInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label>Name<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="name" aria-describedby="name"
              formControlName="name"
              [ngClass]="{ 'is-invalid': isNameInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label>Email<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <input matInput aria-label="email" aria-describedby="email"
              formControlName="email"
              [ngClass]="{ 'is-invalid': isEmailInvalid() }">
            <mat-error>This field is required.</mat-error>
          </mat-form-field>

          <label>Purpose<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <mat-select formControlName="purpose" name="purpose" class="regular">
              <mat-option *ngFor="let mp of purposes" [value]="mp.id">
                {{mp.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label class="col-form-label">Message<span class="text-danger">&nbsp;*</span></label>
          <mat-form-field appearance="fill">
            <textarea matInput rows="6" formControlName="message"></textarea>
            <mat-error>This field is required.</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
    <div *ngIf="contactSent">
      <div class="row">
        <p>Thank you for your message. We will reply to you as soon as possible.</p>
        <p><a class="link-info" (click)="reset()">Send another message</a></p>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" *ngIf="!contactSent">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="accent" (click)="submit()" [disabled]="sending || contactForm.get('message')?.errors">
      <span *ngIf="!sending">Send</span>
      <span *ngIf="sending">Sending...</span>
    </button>
  </div>
</div>
