import { createActionGroup, props} from '@ngrx/store'
import { Release, XAPIRelease } from 'src/app/model';

export const releaseActions = createActionGroup({
  source: 'Release',
  events: {
    getReleaseByUpc: props<{upc: string}>(),
    getReleaseByUpcSuccess: props<{xrelease:XAPIRelease}>(),
    getReleaseByUpcError: props<{error: string}>(),

    getReleasesByArtistId: props<{quansicId: string}>(),
    getReleasesByArtistIdSuccess: props<{releases:Release[]}>(),
    getReleasesByArtistIdError: props<{error: string}>(),

    getReleasesByRecordingId: props<{isrc: string}>(),
    getReleasesByRecordingIdSuccess: props<{releases:Release[]}>(),
    getReleasesByRecordingIdError: props<{error: string}>()
  }
})
