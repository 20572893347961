import { createActionGroup, props} from '@ngrx/store'
import { Work, XAPIWork } from 'src/app/model';

export const workActions = createActionGroup({
  source: 'Work',
  events: {
    getWorkByBowi: props<{bowi: string}>(),
    getWorkByIswc: props<{iswc: string}>(),
    getWorkByIdSuccess: props<{xWork: XAPIWork}>(),
    getWorkByIdError: props<{error: string}>(),

    getWorksByArtistId: props<{quansicId: string}>(),
    getWorksByArtistIdSuccess: props<{works: Work[]}>(),
    getWorksByArtistIdError: props<{error: string}>(),

    getWorksByRecordingId: props<{isrc: string, offset: number}>(),
    getWorksByRecordingIdSuccess: props<{works: Work[], total: number}>(),
    getWorksByRecordingIdError: props<{error: string}>()
  }
})
