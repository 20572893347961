import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpHelperService} from "./http.service";
import { LoginService } from './login.service';
import { UserData } from '../model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  http = inject(HttpClient)
  httpHelperService = inject(HttpHelperService)
  loginService = inject(LoginService)

  private bffAPIUrl = this.httpHelperService.getBffDomain();  // URL to web api

  constructor() { }

  errorHandler(err: HttpErrorResponse): Observable<never> {
    let errorMessage;
    if (err.error instanceof Error) {
      errorMessage = "An error occurred [" + err.message + "]";
    } else {
      errorMessage = "Cannot connect to local server [" + err.name + "]";
    }
    return throwError('Something bad happened; please try again later.');
  }


  contactMessage(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/contactUs`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }

  contactMessageInternal(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/contactUs/internal`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }


  feedbackMessage(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/feedback`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }
  feedbackMessageInternal(data: any): Observable<any> {
    const url = `${this.bffAPIUrl}/api/email/send/feedback/internal`;
    return this.http.post<any>(url, data).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    );
  }

  exportEventInternalMessage(data: any): void {
    const user:UserData|null = this.loginService.getUserData()
    const url = `${this.bffAPIUrl}/api/email/send/export-event/internal`;
    this.http.post<any>(url, {
      ...data,
      email: user?.email,
      name: user?.name
    }).pipe(
      catchError((err) => {
        return this.errorHandler(err);
      })
    ).subscribe();
  }
}
