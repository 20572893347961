import { ChangeDetectionStrategy, Component, Input, inject } from "@angular/core";
import { Artist } from "src/app/model";
import { ImageService } from "src/app/services/image.service";
import { Skeleton } from "src/app/shared/store/state";

@Component({
  selector: 'disambig-item',
  templateUrl: './disambig-item.component.html',
  styleUrls: ['./disambig-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisambigItemComponent {
  imageService = inject(ImageService)
  @Input() artist!: Artist;
  @Input() skeleton!: Skeleton;
}
