import { ActivatedRoute, ParamMap, Router} from "@angular/router";
import { Directive, OnDestroy} from "@angular/core";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { Subject, takeUntil, tap } from "rxjs";

@Directive()
export class TabsController implements OnDestroy {
  _router:Router;
  _route:ActivatedRoute;
  tabActive : number|null = null;
  destroy$ = new Subject<void>();

  constructor(router:Router, route:ActivatedRoute) {
    this._router = router;
    this._route = route;
  }

  readActiveTabFromParam(matTabGroup: MatTabGroup){
    if(!matTabGroup) return;
    return this._route.queryParamMap.subscribe((paramsMap:ParamMap) => {
      matTabGroup.selectedIndex = (paramsMap.get('tab') ?? "0");
    })
  }

  readActiveTab(){
    return this._route.queryParamMap
    .pipe(
      takeUntil(this.destroy$),
      tap((paramsMap:ParamMap) => {
        this.tabActive = parseInt(paramsMap.get('tab') ?? "0");
      })
    )
    .subscribe();
  }

  selectedTabChange(event: MatTabChangeEvent){
    const tabIndex = event.index || 0;
    this._router.navigate([], { queryParams: { tab: tabIndex } });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
