<div class="entity-page">
  <div class="name-block">
    <h1>Recording</h1>
    <div class="name">
      <h1><skeleton shape="small"></skeleton></h1>
    </div>
  </div>
  <div class="info">
    <div class="picture">
      <div>
        <skeleton shape="circle"></skeleton>
      </div>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="information">
      <h2>Information</h2>
      <div><span class="strong">Duration:</span> <skeleton shape="small"></skeleton></div>
      <div><span class="strong">Year of Recording:</span> <skeleton shape="small"></skeleton></div>
      <div><span class="strong">Country of Recording:</span> <skeleton shape="small"></skeleton></div>
    </div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">ISRC:</span> <skeleton shape="small"></skeleton></div>
        <div class="identifier"><span class="strong">Spotify Track Id:</span> <skeleton shape="small"></skeleton></div>
        <div class="identifier"><span class="strong">Apple Track Id:</span> <skeleton shape="small"></skeleton></div>
      </div>
    </div>
    <div class="crtc" *ngIf="isCrtcUser">
      <h2>CRTC</h2>
      <div><div class="strong">MAIPL score: </div><skeleton shape="small"></skeleton></div>
      <div><div class="strong">Is Cancon: </div><skeleton shape="small"></skeleton></div>
      <div><div class="strong">Language: </div><skeleton shape="small"></skeleton></div>
      <div><div class="strong">Sub-Category: </div><skeleton shape="small"></skeleton></div>
      <div><div class="strong">Is emerging:  </div><skeleton shape="small"></skeleton></div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Main Artists">
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Releases</div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Works</div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
