import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppHomeComponent } from './app-home/app-home.component';
import {AuthGuard} from "./services/auth-guard";
import {AppRegisterComponent} from "./login/app-register/app-register.component";
import { AppLoginComponent } from './login/app-login/app-login.component';
import { AppRecoverPasswordComponent } from './login/app-recover-password/app-recover-password.component';
import { AppLoginKeyComponent } from './login/app-login-key/app-login-key.component';
import { AppRecordingComponent } from './app-recording/app-recording.component';
import { AppArtistComponent } from './app-artist/app-artist.component';
import { AppReleaseComponent } from './app-release/app-release.component';
import { AppWorkComponent } from './app-work/app-work.component';
import { AppDisambigComponent } from './app-disambig/app-disambig.component';
import { AppLandingComponent } from './app-landing/app-landing.component';
import { NotFoundPage } from './errors/not-found/not-found.page';

const routes: Routes = [
  { path: '', redirectTo: 'app-home', pathMatch: 'full'},
  { path: '', component: AppHomeComponent, canActivate: [AuthGuard],
    children: [
      { path: 'app-home', component: AppLandingComponent},
      { path: 'app-disambiguation', component: AppDisambigComponent },
      { path: 'app-disambiguation/:searchTerm', component: AppDisambigComponent },
      { path: 'app-party/:quansicId', component: AppArtistComponent },
      { path: 'app-party/:idType/:id', component: AppArtistComponent },
      { path: 'app-recording/:id', component: AppRecordingComponent },
      { path: 'app-release/:id', component: AppReleaseComponent },
      { path: 'app-work/:id', component: AppWorkComponent },
      // { path: 'not-found', component: NotFoundPage },
    ]
  },
  { path: 'app-register', 	component: AppRegisterComponent },
  { path: 'app-recover', 	component: AppRecoverPasswordComponent },
  { path: 'app-login', 	component: AppLoginComponent },
  { path: 'app-login-key', 	component: AppLoginKeyComponent},
  // { path: 'app-home/any', 	component: AppHomeComponent, canActivate: [AuthGuard] },
  // { path: 'app-home/:idType/:id', 	component: AppHomeComponent,  canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
