<table-empty *ngIf="dataSource.data.length == 0" entity="Work"></table-empty>

<div [ngClass]="{'hidden': dataSource.data.length == 0}">
  <table-controls [totalDataCount]="dataSource.data.length"></table-controls>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="q2">
      <mat-header-cell *matHeaderCellDef mat-sort-header="q2Score"> Q2 </mat-header-cell>
      <mat-cell *matCellDef="let work">
        {{work.skeleton}}
        <skeleton shape="large" *ngIf="work.skeleton"></skeleton>
        <span *ngIf="!work.skeleton">{{work.q2Score}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bowi">
      <mat-header-cell *matHeaderCellDef> BOWI </mat-header-cell>
      <mat-cell *matCellDef="let work">
        <skeleton shape="small" *ngIf="work.skeleton"></skeleton>
        <span *ngIf="!work.skeleton">{{work.bowi}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="iswc">
      <mat-header-cell *matHeaderCellDef mat-sort-header> ISWC </mat-header-cell>
      <mat-cell *matCellDef="let work">
        <skeleton shape="small" *ngIf="work.skeleton"></skeleton>
        <span *ngIf="!work.skeleton">{{work.iswc}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Work Title </mat-header-cell>
      <mat-cell *matCellDef="let work">
        <skeleton shape="autosm" *ngIf="work.skeleton"></skeleton>
        <div *ngIf="!work.skeleton">
          <a [routerLink]="['/app-work', work.bowi || work.iswc]">{{work.title}}</a>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="artist" >
      <mat-header-cell *matHeaderCellDef mat-sort-header> Artist / Role </mat-header-cell>
      <mat-cell *matCellDef="let work">
        <skeleton shape="small" *ngIf="work.skeleton"></skeleton>
        <div *ngIf="!work.skeleton">
          <div *ngFor="let artist of work.contributors" class="contributors">
            <party-link [party]="artist"></party-link><mat-icon>arrow_forward</mat-icon> {{artist.role}}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row matRipple *matRowDef="let work; columns: columns;" ></mat-row>
  </mat-table>
</div>
