import { YDBService } from '../../services/y-db.service';
import { InlineEditService } from '../../services/inline-edit.service';
import { XCrtcMaiplStatus, Recording } from '../../model';
import { inject, Injectable } from "@angular/core";
import { iif, map, Observable, of, tap } from 'rxjs';
import { LoginService } from '@githubquansic/web-auth/ng';
import {Language, iso6392} from 'iso-639-2'
import { CrtcCategory, CrtcMaiplScore, CrtcSubCategory, Iso6392Language, MaiplCategory } from './crtc-model';
import { Store } from '@ngrx/store';
import { recordingQuery } from 'src/app/shared/store/recording/recording.selectors';


@Injectable({
  providedIn: 'root'
})
export class CrtcService {
  inlineEditService = inject(InlineEditService)
  yDbService = inject(YDBService)
  loginService = inject(LoginService)
  readonly store = inject(Store);


  emptyMaplScore = {
    code: '',
    music: false,
    artist: false,
    instrument: false,
    performance: false,
    lyrics: false,
    source: ''
  }

  isCrtcUser(){
    const userData = this.loginService.getUserData()
    return userData?.company?.toLowerCase() === "crtc"
  }

  maiplScoreStatus$(recording: Recording|null): Observable<CrtcMaiplScore> {
    return iif(
      () => recording !== null,
      of(recording),
      this.store.select(recordingQuery.selectRecording)
    ).pipe(
      map((recording) => this.getMaiplScore(recording!.crtcMaiplStatus)),
      map((score) => ({
        ...score,
        code: this.getMaiplCode(score)
      }))
    )
  }

  maiplCanconStatus$(recording: Recording): Observable<boolean> {
    return of(recording.crtcMaiplStatus?.isCanadianContent)
    .pipe(
      map((isCancon) => {
        return !!isCancon;
      })
    )
  }

  getMaiplScore (crtcMaiplStatus: XCrtcMaiplStatus|undefined): CrtcMaiplScore {
    let score: CrtcMaiplScore = this.emptyMaplScore
    if(crtcMaiplStatus !== undefined){
      const classifications = crtcMaiplStatus.classifications
      if(classifications){
        const maiplMap  = new Map(Object.entries(classifications));
        const code = maiplMap.get('CRTC')||maiplMap.get('Quansic')||'';
        const codeLC = code.toLowerCase()
        return {
          code,
          music: codeLC.includes('m'),
          artist: codeLC.includes('a'),
          instrument: codeLC.includes('i'),
          performance: codeLC.includes('p'),
          lyrics: codeLC.includes('l'),
          source: 'Quansic'
        }
      }
    }
    return score;
  }

  getMaiplCode(crtcMaiplScore: CrtcMaiplScore) {
    return (crtcMaiplScore.music ? 'M' :'') + (crtcMaiplScore.artist ? 'A' : '') +(crtcMaiplScore.instrument ? 'I': '') +
      (crtcMaiplScore.performance ? 'P' : '') + (crtcMaiplScore.lyrics ? 'L' : '')
  }





  getIso6392Languages(): Iso6392Language[]  {
    return iso6392
        .filter(l => l.iso6392B !== undefined)
        .sort((l) => ['fre', 'eng'].includes(l.iso6392B) ? -1: 0)
        .map(l => ({name: l.name, iso6392: l.iso6392B}))
  }
  getLanguageByCode(code: string): Language|undefined {
    return iso6392.find(lang => lang.iso6392B === code);
  }
  
  getCrtcCategories(): CrtcCategory[] {
    return [
      { categoryId: "1", label: "Créations orales", subCategories: [
        {subCategoryId: "11", label: "Nouvelles"},
        {subCategoryId: "12", label: "Créations orales – autres"},
      ]},
      { categoryId: "2", label: "Musique populaire", subCategories: [
        {subCategoryId: "21", label: "Musique populaire, rock et de danse"},
        {subCategoryId: "22", label: "Country et genre country"},
        {subCategoryId: "23", label: "Musique acoustique"},
        {subCategoryId: "24", label: "Musique de détente"},
      ]},
      { categoryId: "3", label: "Musique pour auditoire spécialisé", subCategories: [
        {subCategoryId: "31", label: "Musique de concert"},
        {subCategoryId: "32", label: "Folklore et genre folklore"},
        {subCategoryId: "33", label: "Musique du monde et musique internationale"},
        {subCategoryId: "34", label: "Jazz et blues"},
        {subCategoryId: "35", label: "Religieux et non classique"},
        {subCategoryId: "36", label: "Musique expérimentale"},
      ]},
      { categoryId: "4", label: "Productions musicales", subCategories: [
        {subCategoryId: "41", label: "Thèmes, continuités, effets musicaux"},
        {subCategoryId: "42", label: "Vérifications techniques"},
        {subCategoryId: "43", label: "Indicatif musical de la station"},
        {subCategoryId: "44", label: "Identification musicale des annonceurs, des émissions"},
        {subCategoryId: "45", label: "Publicité musicale pour certains annonceurs et certaines émissions"},
      ]},
      { categoryId: "5", label: "Publicité", subCategories: [
        {subCategoryId: "51", label: "Message commercial"},
        {subCategoryId: "52", label: "Identification du commanditaire"},
        {subCategoryId: "53", label: "Promotion avec mention du commanditaire"},
      ]}
    ]
  }

  getSubCategoryById(id: string): CrtcSubCategory|undefined {
    return this.getCrtcCategories()
        .reduce((acc: CrtcSubCategory[], category: CrtcCategory ) => {
            return acc.concat(category.subCategories)
          }, [] as CrtcSubCategory[])
        .find(subCategory => subCategory.subCategoryId === id)
  }
}
