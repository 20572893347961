export type CrtcCategory = {
    categoryId : string
    label: string
    subCategories: CrtcSubCategory[]
}

export type CrtcSubCategory = {
    subCategoryId: string
    label: string
}

export type Iso6392Language = {
    name: string
    iso6392: string
}

export type MaiplCategory = 'music'|'artist'|'instrument'|'performance'|'lyrics'
export type IsCanconCategory = "isCanadianContent"
export type CrtcMaiplScore = {
  code: string
  source: string
} & {[key in MaiplCategory]: boolean}
