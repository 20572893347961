import { XAPIParty, Artist, Identifiers, Relationship, Relationships } from "src/app/model";

export const toArtistFromXParty = (xParty: XAPIParty, ): Artist => {
    return {
      id: xParty.ids.quansicId,
      ids: {
        quansicId: xParty.ids.quansicId,
        isnis: xParty.ids.isnis || [],
        mergedIsnis: xParty.ids.mergedIsnis || [],
        ipis: xParty.ids.ipis?.map(formatIsni) || [],
        ipns: xParty.ids.ipns || [],
        musicBrainzIds: xParty.ids.musicBrainzIds || [],
        discogsIds: xParty.ids.discogsIds || [],
        appleIds: xParty.ids.appleIds || [],
        spotifyIds: xParty.ids.spotifyIds || [],
        wikidataIds: xParty.ids.wikidataIds || [],
        amazonIds: xParty.ids.amazonIds || [],
        deezerIds: xParty.ids.deezerIds || [],
        luminateIds: xParty.ids.luminateIds || [],
        gracenoteIds: xParty.ids.gracenoteIds || [],
        tmsIds: xParty.ids.tmsIds || [],
      },
      entityType: 'party',
      name: xParty.name,
      firstname: xParty.firstname,
      lastname: xParty.lastname,
      comments: xParty.comment,
      role: xParty.role,
      type: xParty.type,
      gender: xParty.gender,
      birthdate: xParty.birthdate,
      deathdate: xParty.deathdate,
      cover: xParty.visual,
      contributorType: xParty.contributorType,
      nationality: xParty.nationality
    } as Artist
}

export const formatIdentifiers = (ids:Identifiers): Identifiers => {
  return {
    ...ids,
    isnis: ids.isnis?.map(formatIsni),
  } as Identifiers
}

export const formatIsni = (isni:string): string => {
  return isni.replace(/^([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})([a-zA-Z0-9]{4})$/g, '$1 $2 $3 $4');
}

export const toRelationships = (relationships: Relationship[]): Relationships => {
  return {
    hasMember: relationships ? relationships?.filter((rel: Relationship) => rel.relationshipType === "HasMember") : [],
    isMemberOf: relationships ? relationships?.filter((rel: Relationship) => rel.relationshipType === "IsMemberOf") : [],
    aka: relationships ? relationships?.filter((rel: Relationship) => rel.relationshipType === "Aka") : []
  } as Relationships
}
