import { Component, Input, OnDestroy, inject } from "@angular/core";
import { Observable, Subject, combineLatest, iif, of, pairwise, startWith, takeUntil, tap } from "rxjs";
import { CrtcMaiplScore, IsCanconCategory, MaiplCategory } from "../../services/crtc-model";
import { CrtcService } from "../../services/crtc.service";
import { CandidateChangeRequest, InlineEditService } from "src/app/services/inline-edit.service";
import { Recording } from "src/app/model";
import { FormControl, FormGroup } from "@angular/forms";
import { MaiplScoreRulesService } from "./rules/maipl-score-rules.service";
import { MaiplScoreRuleResult } from "./rules/maipl-score-rules";

@Component({
    selector: 'maipl-score-edit',
    templateUrl: './maipl-score-edit.component.html',
    styleUrls: ['./maipl-score-edit.component.scss'],
})
export class MaiplScoreEditComponent implements OnDestroy{
    readonly inlineEditService = inject(InlineEditService)
    readonly maiplScoreRulesService = inject(MaiplScoreRulesService)
    readonly crtcService = inject(CrtcService)

    @Input() recording!: Recording;

    onDestroy$ = new Subject<void>()

    formGroup = new FormGroup({
        maiplScoreMusic: new FormControl<boolean>(false),
        maiplScoreArtist: new FormControl<boolean>(false),
        maiplScorePerf: new FormControl<boolean>(false),
        maiplScoreInstr: new FormControl<boolean>(false),
        maiplScoreLyrics: new FormControl<boolean>(false),
        isCancon: new FormControl<boolean>(false),
    })

    maiplScore$!: Observable<CrtcMaiplScore>;
    isCanadianContent$!: Observable<boolean>;

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    ngOnInit() {    
        this.setupInitialValues()
        this.listenToMaiplScoreEdits(this.formGroup.controls['maiplScoreMusic'], 'music'); 
        this.listenToMaiplScoreEdits(this.formGroup.controls['maiplScoreArtist'], 'artist'); 
        this.listenToMaiplScoreEdits(this.formGroup.controls['maiplScoreInstr'], 'instrument'); 
        this.listenToMaiplScoreEdits(this.formGroup.controls['maiplScorePerf'], 'performance'); 
        this.listenToMaiplScoreEdits(this.formGroup.controls['maiplScoreLyrics'], 'lyrics'); 
        this.listenToIsCanconEdits(this.formGroup.controls['isCancon']); 
        this.listenToMaiplScoreChanges();
        this.rulesResult = this.rulesResult = this.maiplScoreRulesService.applyRules(this.formGroup)
    }

    setupInitialValues() {
        combineLatest([
            this.crtcService.maiplScoreStatus$(this.recording),
            this.crtcService.maiplCanconStatus$(this.recording)
        ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(([score, isCancon]) => {
            this.formGroup.patchValue({
                maiplScoreMusic: score.music,
                maiplScoreArtist: score.artist,
                maiplScorePerf: score.performance,
                maiplScoreInstr: score.instrument,
                maiplScoreLyrics: score.lyrics,
                isCancon: isCancon
            },
            {emitEvent: false})
        })
        this.inlineEditService.editMode$.subscribe((editMode) => {
            if (!editMode) {
                this.formGroup.disable()
            } else {
                this.formGroup.enable()
            }
        })
    }

    listenToMaiplScoreEdits(scoreFormControl: FormControl, maiplCategory: MaiplCategory|IsCanconCategory
    ) {
        combineLatest([
            this.crtcService.maiplScoreStatus$(this.recording),
            scoreFormControl.valueChanges
                .pipe(
                    takeUntil(this.onDestroy$),
                    startWith(scoreFormControl.value),
                    pairwise(),
                )
        ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
            next: ([score, [prev, next]]) => {
                const musicGraphValue = score[maiplCategory as MaiplCategory];
                this.publishChangeRequest(prev, next, `crtcMaiplScore.${maiplCategory}`, musicGraphValue)
            }
        })
    }
    listenToIsCanconEdits(scoreFormControl: FormControl) {
        combineLatest([
            this.crtcService.maiplCanconStatus$(this.recording),
            scoreFormControl.valueChanges
            .pipe(
                takeUntil(this.onDestroy$),
                startWith(scoreFormControl.value),
                pairwise(),
            )
        ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
            next: ([isCancon, [prev, next]]) => {
                this.publishChangeRequest(prev, next, `isCanadianContent`, isCancon)
            }
        })
    }
    publishChangeRequest(originalValue: any, newValue: any, maiplCategory: string, graphValue: any) {
        const changeCandidate: CandidateChangeRequest = {
            entityId: this.recording.id,
            entityType: this.recording.entityType,
            entityName: this.recording.title,
            displayId: this.recording.id,
            property: maiplCategory,
            originalValue: originalValue,
            newValue: newValue,
            ySources: this.recording.ySources
        };
        this.inlineEditService.pushChangeRequestCandidate(changeCandidate, graphValue);
    }

    rulesResult: MaiplScoreRuleResult = {rules: [], changeRequests: []}
    listenToMaiplScoreChanges() {
        this.formGroup.valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
            next: () => {
                this.rulesResult = this.maiplScoreRulesService.applyRules(this.formGroup)
                if(this.rulesResult.changeRequests) {
                    this.rulesResult.changeRequests.forEach(changeRequest => {
                        this.crtcService.maiplCanconStatus$(this.recording)
                        .pipe(
                            takeUntil(this.onDestroy$),
                        )
                        .subscribe((graphValue) => {
                            this.publishChangeRequest(changeRequest.originalValue, changeRequest.newValue, changeRequest.property, graphValue)
                        })
                    })
                }
            }
        })
    }
}