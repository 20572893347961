import { createFeature, createReducer, on } from "@ngrx/store";
import { RecordingOfWork, Work, WorkOfRecording } from "src/app/model";
import { Status } from "../state";
import { workActions } from "./work.actions";
import { toWorkFromXApiWork } from './work.domain';
import { toRecordingsOfWorkFromXAPIRecording } from "../recording/recording.domain";

export interface WorkState {
  work: Work|null;
  works: Work[]|null;
  workContributors: WorkOfRecording[]|null;
  workContributorsTotal: number;
  recordings: RecordingOfWork[]|null;
  status: Status;
  error: string|undefined;
}
export const initialState: WorkState = {
  work: null,
  works: null,
  workContributors: null,
  workContributorsTotal: 0,
  recordings: null,
  status: 'idle',
  error: undefined
}

export const workFeature = createFeature({
  name: 'work',
  reducer: createReducer(
    initialState,
    on(workActions.getworkbybowi, (state, {bowi: string}) => ({
      ...state,
      work: null,
      status: 'loading' as Status
    })),
    on(workActions.getworkbyiswc, (state, {iswc: string}) => ({
      ...state,
      work: null,
      status: 'loading' as Status
    })),
    on(workActions.getworkbyidsuccess, (state, {xWork}) =>  {
      return {
        ...state,
        work: toWorkFromXApiWork(xWork),
        recordings: toRecordingsOfWorkFromXAPIRecording(xWork.recordings || []),
        status: 'success' as Status
      }
    }),
    on(workActions.getworkbyiderror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),
    on(workActions.getworksbyartistid, (state) => ({
      ...state,
      work: null,
      status: 'loading' as Status
    })),
    on(workActions.getworksbyartistidsuccess, (state, {works}) => ({
      ...state,
      works,
      status: 'success' as Status
    })),
    on(workActions.getworksbyartistiderror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),
    on(workActions.getworksbyrecordingid, (state) => ({
      ...state,
      workContributors: null,
      workContributorsTotal: 0,
      status: 'loading' as Status
    })),
    on(workActions.getworksbyrecordingidsuccess, (state, {works, total}) => ({
      ...state,
      workContributors: works,
      workContributorsTotal: total,
      status: 'success' as Status
    })),
    on(workActions.getworksbyrecordingiderror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    }))
  )
})
