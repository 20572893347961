import {Injectable} from "@angular/core";
import {YApiResponse} from "../model";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {LoginService} from "./login.service";
import {HttpHelperService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class LockService {

  private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

  constructor(private http: HttpClient, private httpHelperService: HttpHelperService, private loginService: LoginService) { }

  errorHandler(err: HttpErrorResponse): any {
    return throwError(() => err);
  }

  isLocked(qPartyId: string): Observable<any> {
    const url = `${this.bffApiUrl}/api/y/party/${qPartyId}/lock/status`;
 
    return this.http.get<YApiResponse>(url, {withCredentials: true}).pipe(
      map((response: YApiResponse) => {
        return response.results === "Locked";
      }),
      catchError((err:any, caught: Observable<boolean>) => {
        return this.errorHandler(err);
      })
    );
  }

  lock(qPartyId: string, comment: string): Observable<any> {
    const url = `${this.bffApiUrl}/api/y/party/${qPartyId}/lock`;
    const user = this.loginService.getUserData();
    return this.http.put<YApiResponse>(url, {
      userId: user?.apiKey || user?.email,
      userName: user?.name,
      comment
    }, {withCredentials: true}).pipe(
      catchError((err: any) => {
        return this.errorHandler(err);
      })
    );
  }

  unlock(qPartyId: string, comment: string): Observable<any>{
    const url = `${this.bffApiUrl}/api/y/party/${qPartyId}/unlock`;
    const user = this.loginService.getUserData();
    return this.http.put<YApiResponse>(url, {
      userId: user?.apiKey || user?.email,
      userName: user?.name,
      comment
    }, {withCredentials: true}).pipe(
      catchError((err: any) => {
        return this.errorHandler(err);
      })
    );
  }
}
