import { WorkOfRecording, XCrtcMaiplStatus } from './../../model';
import { Country } from '@angular-material-extensions/select-country';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, inject} from '@angular/core';
import { FormArray, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { of, map, tap, EMPTY, Observable, Subject, takeUntil, combineLatest } from 'rxjs';
import { Work, Recording, Artist } from 'src/app/model';
import { CandidateChangeRequest, InlineEditService } from 'src/app/services/inline-edit.service';
import { CrtcService } from '../services/crtc.service';
import { CrtcMaiplScore, MaiplCategory } from '../services/crtc-model';
import { Store } from '@ngrx/store';
import { workActions } from 'src/app/shared/store/work/work.actions';
import { workQuery } from 'src/app/shared/store/work/work.selectors';


export interface ContributorOfWork {
  id: string
  work: Work
  contributor: Artist
}
@Component({
  selector: 'maipl-edit-panel',
  templateUrl: './maipl-edit-panel.component.html',
  styleUrls: ['./maipl-edit-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaiplEditPanelComponent {
  readonly inlineEditService = inject(InlineEditService)
  readonly crtcService = inject(CrtcService)

  @Input('recording') recording!: Recording;
  @Input('works$') works$!: Observable<Work[]>;

  languageList = this.crtcService.getIso6392Languages()
  categoriesList = this.crtcService.getCrtcCategories();


  ngAfterViewInit() {
    const rcTableContainer = document.querySelector('.recording-table-container');
    const rcHeaderRow = rcTableContainer?.querySelector('mat-header-row');
    const rcMatTable = rcTableContainer?.querySelector('mat-table');
    if (rcTableContainer && rcHeaderRow && rcMatTable) {
      rcTableContainer.insertBefore(rcHeaderRow, rcMatTable);
    }

    const wkTableContainer = document.querySelector('.work-table-container');
    const wkHeaderRow = wkTableContainer?.querySelector('mat-header-row');
    const wkMatTable = wkTableContainer?.querySelector('mat-table');
    if (wkTableContainer && wkHeaderRow && wkMatTable) {
      wkTableContainer.insertBefore(wkHeaderRow, wkMatTable);
    }
  }

}
