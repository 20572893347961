import { Component, Input } from "@angular/core";

@Component({
  selector: 'skeleton',
  template: "<div class='skeleton {{shape}}'></div>",
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent{
  @Input() shape: 'large'|'small'|'tiny'|'circle'|'tiny-circle'|'autolg'|'autosm' = 'small';
}
