import { Router } from '@angular/router';
import { LoginService } from '@githubquansic/web-auth/ng';
import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LogService } from '../../services/log.service';


@Component({
  selector: 'app-login-key',
  templateUrl: './app-login-key.component.html',
  styleUrls: ['./app-login-key.component.scss']
})

export class AppLoginKeyComponent {
  form = new FormGroup({
    apiKey: new FormControl('', [Validators.required]),
  });
  authFailed = false;
  submitted = false;
  queryInProgress = false;

  constructor(private loginService: LoginService, private logService: LogService, private router: Router){}

  login() {
    this.submitted = true;

    const apiKey = this.form.get('apiKey')?.value;
    if(apiKey) {

      this.queryInProgress = true;
      this.loginService.login(apiKey).subscribe({
        next: () => {
          this.logService.recordLogin();
          this.queryInProgress = false;
          this.submitted = false;
          this.router.navigate(['/app-home/any']);
        },
        error: () => {
          this.queryInProgress = false;
          this.submitted = false;
          this.form.setErrors({loginFailed: true});
          this.authFailed = true;
        }
      })
    }
  }

  isApiKeyEmpty(): boolean {
    return this.form.controls['apiKey']?.errors !== null && this.submitted;
  }
}
