import { Component, Input, OnChanges, SimpleChanges, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import { artistActions } from "src/app/shared/store/artist/artist.actions";
import { toRelationships } from "src/app/shared/store/artist/artist.domain";
import { ArtistState } from "src/app/shared/store/artist/artist.reducer";
import { selectRelationships, selectRelationshipsStatus } from "src/app/shared/store/artist/artist.selectors";

@Component({
  selector: 'relationships',
  templateUrl: './relationships.component.html'
})
export class RelationshipsComponent implements OnChanges {
  store = inject(Store<ArtistState>)
  @Input() quansicId!: string;

  relationships$ = this.store.select(selectRelationships).pipe(
    map(relationships => toRelationships(relationships))
  );
  status$ = this.store.select(selectRelationshipsStatus);

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['quansicId']) {
      this.store.dispatch(artistActions.getrelationships({quansicId: this.quansicId}))
    }
  }
}
