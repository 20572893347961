<app-loading></app-loading>

<div class="container">
  <app-navbar></app-navbar>
  <div class="main">
    <router-outlet></router-outlet>
    <!-- <search *ngIf="context == 'home'"></search>

    <page-error *ngIf="error != null" [error]="error" [errorMsg]="errorMsg" [identifiers]="currentSearchIds"></page-error>

    <disambig *ngIf="isShowDisambig" [artists]="artistsForDisambiguation" [searchTerm]="searchTerm"></disambig>

    <app-artist *ngIf="selectedArtist != null" [artist]="selectedArtist"></app-artist>

    <app-recording *ngIf="selectedRecording != null"></app-recording>

    <app-release *ngIf="selectedRelease != null"
      [release]="selectedRelease"
      [artists]="artists"
      [recordings]="recordings"></app-release>

    <app-work *ngIf="selectedWork != null"
      [work]="selectedWork"
      [recordings]="recordings"></app-work> -->
  </div>
</div>

<!--<div class="left-column" >-->
<!--  <div class="vertical">-->
<!--    <span *ngIf="selectedArtist != null">ARTIST</span>-->
<!--    <span *ngIf="selectedRelease != null">RELEASE</span>-->
<!--    <span *ngIf="selectedRecording != null">RECORDING</span>-->
<!--    <span *ngIf="selectedWork != null">WORK</span>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="main">-->
<!--  <div class="content">-->
<!--    <search *ngIf="context == 'search' || (error==null && context == 'home')" [context]="context"></search>-->

<!--    <page-error *ngIf="error != null" [error]="error" [errorMsg]="errorMsg" [identifiers]="currentSearchIds"></page-error>-->

<!--    <disambig *ngIf="isShowDisambig" [artists]="artistsForDisambiguation" [searchTerm]="searchComponent.getSearchTerm()"></disambig>-->

<!--    <app-artist *ngIf="selectedArtist != null"-->
<!--                  [artist]="selectedArtist"-->
<!--                  [recordings]="recordings"-->
<!--                  [releases]="releases"-->
<!--                  [works]="works"></app-artist>-->
<!--    <app-recording *ngIf="selectedRecording != null"-->
<!--                   [recording]="selectedRecording"-->
<!--                   [artists]="artists"-->
<!--                   [releases]="releases"-->
<!--                   [works]="works"></app-recording>-->
<!--    <app-release *ngIf="selectedRelease != null"-->
<!--                   [release]="selectedRelease"-->
<!--                   [artists]="artists"-->
<!--                   [recordings]="recordings"></app-release>-->
<!--    <app-work *ngIf="selectedWork != null"-->
<!--                 [work]="selectedWork"-->
<!--                 [recordings]="recordings"></app-work>-->
<!--  </div>-->
<!--</div>-->
