import { Skeleton, skeleton } from 'src/app/shared/store/state';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "table-recordings-skeleton",
  templateUrl: "./table-recordings-skeleton.component.html",
  styleUrls: ["./table-recordings-skeleton.component.scss"],
})
export class TableRecordingsSkeletonComponent implements OnInit {

  datasource = new MatTableDataSource<Skeleton>(skeleton(5));
  columns = ['isrc', 'audio', 'title', 'year', 'artist'];

  constructor() { }

  ngOnInit(): void {
  }

}
