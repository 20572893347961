import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class IdentifiersService {
  isniRegex = /^\t?\n?\r?\d{4}\ ?\d{4}\ ?\d{4}\ ?\d{3}[\dxX]\t?\n?\r?$/;
  isrcRegex = /^\t?\n?\r?[A-Za-z]{2}-?\w{3}-?\d{2}-?\d{5}\t?\n?\r?$/;          // CA-ZZ0-13-00001
  upcRegex = /^\t?\n?\r?\d{12,13}\t?\n?\r?$/;                               // 190295245214
  iswcRegex = /^\t?\n?\r?T-?\d{3}\.?\d{3}\.?\d{3}-?\d\t?\n?\r?$/;   // T-905.029.737-5 or T0030546372
  bowiRegex = /\t?\n?\r?B{1}[0-1]{1}-?[\da-zA-Z]{7}\d{1}-?[\da-zA-Z]{1,2}\t?\n?\r?/; // B1FE43TU790 or B1-FE43TU79-0
  musicBrainzIdRegex = /\b[0-9a-f]{8}\b-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-\b[\da-f]{12}\b\t?\n?\r?/;
  deezerIdRegex = /^\t?\n?\r?\d{10}\t?\n?\r?$/;

  isIsni(searchTerm: string|null): boolean {
    return searchTerm !== null && this.isniRegex.test(searchTerm);
  }
  isMusicBrainzId(searchTerm: string|null): boolean {
    return searchTerm !== null && this.musicBrainzIdRegex.test(searchTerm);
  }
  isIsrc(searchTerm: string|null): boolean {
    return searchTerm !== null && this.isrcRegex.test(searchTerm);
  }
  isIswc(searchTerm: string|null): boolean {
    return searchTerm !== null && this.iswcRegex.test(searchTerm);
  }
  isBowi(searchTerm: string|null): boolean {
    return searchTerm !== null && this.bowiRegex.test(searchTerm);
  }
  isUpc(searchTerm: string|null): boolean {
    return searchTerm !== null && this.upcRegex.test(searchTerm);
  }
  isDeezerId(searchTerm: string|null): boolean {
    return searchTerm !== null && this.deezerIdRegex.test(searchTerm);
  }
}
