import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: 'skeleton-recording',
  templateUrl: './skeleton-recording.component.html',
  styleUrls: ['./skeleton-recording.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonRecordingComponent {
  @Input() isCrtcUser!: boolean
}
