import { MatDialog } from '@angular/material/dialog';
import { Component, Input } from '@angular/core';
import { DialogAdditionalIdsComponent } from '../dialog-additional-ids/dialog-additional-ids.component';

@Component({
  selector: 'artist-identifier',
  templateUrl: './artist-identifier.component.html',
  styleUrls: ['./artist-identifier.component.css']
})
export class ArtistIdentifierComponent {
  @Input() values: string[];
  @Input() linkTemplate:string|null;
  @Input() idName:string|null;
  links:string[] = [];

  constructor(private dialog: MatDialog) {
    this.values = [];
    this.linkTemplate = null;
    this.idName = null;
  }

  ngOnChanges(): void {
    this.links = [];
    if(this.values != null){
      (this.values as Array<string>).forEach((value) => {
        if(this.linkTemplate != null) {
          const valueRaw = value.replace(/[\.\ ]/g, "");
          this.links?.push(this.linkTemplate.replace("__ID__", valueRaw));
        }
      })
    }
  }

  openAdditionalIdsDialog(){
    this.dialog.open(DialogAdditionalIdsComponent, {
      panelClass: "dialog",
      data: {
        identifiers: this.values,
        links: this.links,
        idName: this.idName
      }
    })
  }

}
