import { artistFeature } from "./artist.reducer"

export const {
  selectArtist,
  selectStatus,
  selectNameVariants,
  selectNameVariantsStatus,
  selectRelationships,
  selectRelationshipsStatus,
  selectArtistsDisambiguation,
  selectError } = artistFeature;

export const artistQuery = {
  selectArtist,
  selectStatus,
  selectArtistsDisambiguation,

  selectNameVariants,
  selectNameVariantsStatus,

  selectRelationships,
  selectRelationshipsStatus,

  selectError
};
