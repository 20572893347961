import { ApiError, Work } from './../model';
import { Component, ViewChild, AfterViewInit, inject } from '@angular/core';
import { Artist, Recording } from "../model";
import { TabsController} from "../_common/tabs.controller";
import { ActivatedRoute, Router } from "@angular/router";
import { TableReleasesComponent } from '../shared/components/table-releases/table-releases.component';
import { TableWorksComponent } from '../shared/components/table-works/table-works.component';
import { ExportService } from '../services/export.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogCrtcComponent } from '../crtc/dialog-crtc/dialog-crtc.component';
import { CrtcService } from '../crtc/services/crtc.service';
import { ExportCRTCService } from '../crtc/services/export-crtc.services';
import { Store } from '@ngrx/store';
import { ReleaseState } from '../shared/store/release/release.reducer';
import { WorkState } from '../shared/store/work/work.reducer';
import { recordingQuery } from '../shared/store/recording/recording.selectors';
import { combineLatest, filter, map, tap, take, takeUntil } from 'rxjs';
import { recordingActions } from '../shared/store/recording/recording.actions';
import { TitleService } from '../shared/data/services/title.service';
import { DialogUtilsService } from '../shared/data/services/dialog-utils.service';
import { YDBService } from '../services/y-db.service';
import { Language } from 'iso-639-2';
import { CrtcSubCategory } from '../crtc/services/crtc-model';
import { workQuery } from '../shared/store/work/work.selectors';

@Component({
  selector: 'app-recording',
  templateUrl: './app-recording.component.html',
  styleUrls: ['./app-recording.component.scss']
})
export class AppRecordingComponent extends TabsController implements AfterViewInit {
  readonly storeWork = inject(Store<WorkState>)
  readonly storeRelease = inject(Store<ReleaseState>)
  readonly storeRecording = inject(Store<WorkState>)
  readonly titleService = inject(TitleService)
  readonly dialogUtilsService = inject(DialogUtilsService)
  readonly yDbService = inject(YDBService)

  @ViewChild(TableReleasesComponent) tableReleasesComponent!: TableReleasesComponent;
  @ViewChild(TableWorksComponent) tableWorksComponent!: TableWorksComponent;

  constructor(
    private exportService: ExportService,
    private exportCRTCService: ExportCRTCService,
    public crtcService: CrtcService,
    private dialog: MatDialog,
    private router:Router,
    private route:ActivatedRoute) {
    super(router, route);
  }

  ApiError = ApiError;

  works$ = this.storeWork.select(recordingQuery.selectWorks)
  .pipe(
    tap((works) => console.log(works)),
  );
  releases$ = this.storeRelease.select(recordingQuery.selectReleases);

  recording$ = this.storeRecording.select(recordingQuery.selectRecording).pipe(
    tap(recording => this.titleService.changeBrowserTitle('recording', recording)),
    tap(recording => this.yDbService.entityLoad$.next(recording)),
    filter(recording => recording !== null),
  );
  mainArtists$ = this.storeRecording.select(recordingQuery.selectArtists).pipe(
    map(contributors => contributors.filter((contributor:Artist) => {
      return contributor.contributorType === "MainArtist"
    }))
  )
  performers$ = this.storeRecording.select(recordingQuery.selectArtists).pipe(
    map(contributors => contributors.filter((contributor:Artist) => {
      return contributor.contributorType !== "MainArtist"
    })),
    map(performers => performers.map(perf => ({...perf, role: perf.role?.replace(/\|/g, ", ",)})))
  )

  recordingStatus$ = this.storeRecording.select(recordingQuery.selectStatus);
  recordingError$ = this.storeRecording.select(recordingQuery.selectError);
  searchParams$ = this.route.params.pipe(
    takeUntil(this.destroy$),
    map(params => (params['id']))
  )

  ngOnInit(){
    this.searchParams$.subscribe(isrc => {
      this.storeRecording.dispatch(recordingActions.getrecordingbyisrc({isrc}));
    })
  }

  ngAfterViewInit(){
    this.readActiveTab()
  }

  exportRecording(recording: Recording) {
  combineLatest([
      this.works$,
      this.releases$,
      this.mainArtists$,
      this.performers$
    ]).pipe(
      take(1),
    ).subscribe({
      next: ([works, releases, mainArtists, performers]) => {
        if(works === null || releases === null || mainArtists === null || performers === null) return;
        if(this.crtcService.isCrtcUser()) {
          this.exportCRTCService.exportRecordingAsExcel(recording, works, releases, mainArtists, performers, recording.title)
        } else {
          this.exportService.exportRecordingAsExcel(recording, works, releases, mainArtists, performers, recording.title)
        }
      }
    })
  }

  crtcMaiplScore$(recording: Recording) {
    return this.crtcService.maiplScoreStatus$(recording)
  }

  openCrtcDetailsDialog() {
    // combineLatest([
    //   this.recording$,
    //   this.works$,
    //   this.storeWork.select(workQuery.selectWorkContributors),
    // ])
    this.recording$.pipe(take(1)).subscribe({
      next: (recording) => {
        this.dialog.open(DialogCrtcComponent, {
          width: '80vw',
          height: '80vh',
          data: {
            recording: recording,
            works$: this.works$
          }
        })
      }
    })
  }

  openFeedbackDialog() {
    this.dialogUtilsService.openFeedbackDialog();
  }

  getCrtcLanguageByCode(languageCode: string): Language|undefined {
    return this.crtcService.getLanguageByCode(languageCode);
  }

  getCrtcSubCategoryById(subCategoryId: string): CrtcSubCategory|undefined {
    return this.crtcService.getSubCategoryById(subCategoryId);
  }
}
