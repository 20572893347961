import { DialogPolicyComponent } from './../dialog-policy/dialog-policy.component';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dialog-terms',
  templateUrl: './dialog-terms.component.html',
  styleUrls: ['./dialog-terms.component.css']
})
export class DialogTermsComponent {
  constructor(private dialog: MatDialog) {}

  showPrivacyPolicyDialog() {
    this.dialog.closeAll();
    this.dialog.open(DialogPolicyComponent,  {
      panelClass: "dialog",
      autoFocus: false
    });
  }
}
