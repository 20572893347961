import { createFeature, createReducer, on } from "@ngrx/store";
import { Artist, Recording, Release, Work, XAPIRelease } from "src/app/model";
import { Skeleton, Status, skeleton } from "../state";
import { releaseActions } from "./release.actions";
import { toReleaseFromXApiRelease } from "./release.domain";
import { toWorkFromXApiWork } from "../work/work.domain";
import { toRecordingFromXAPIRecording } from "../recording/recording.domain";
import { toArtistFromXParty } from "../artist/artist.domain";

export interface ReleaseState {
  releases: Release[]|null;
  release: Release|null;
  recordings: Recording[] | null;
  mainArtists: Artist[] | null;
  status: Status;
  error: string|undefined;
}
export const initialState: ReleaseState = {
  releases: null,
  release: null,
  recordings: null,
  mainArtists: null,
  status: 'idle',
  error: undefined
}

export const releaseFeature = createFeature({
  name: 'release',
  reducer: createReducer(
    initialState,
    on(releaseActions.getreleasebyupc, (state) => ({
      ...state,
      status: 'loading' as Status
    })),
    on(releaseActions.getreleasebyupcsuccess, (state, {xrelease}) =>  {
      return ({
        ...state,
        release: toReleaseFromXApiRelease(xrelease),
        recordings: xrelease.recordings?.map(r => toRecordingFromXAPIRecording(r)) || [],
        mainArtists: xrelease.parties?.map(p => toArtistFromXParty(p)) || [],
        status: 'success' as Status
      })
    }),
    on(releaseActions.getreleasebyupcerror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),
    on(releaseActions.getreleasesbyartistid, (state) => ({
      ...state,
      releases: null,
      status: 'loading' as Status
    })),
    on(releaseActions.getreleasesbyartistidsuccess, (state, {releases}) => ({
      ...state,
      releases,
      status: 'success' as Status
    })
    ),
    on(releaseActions.getreleasesbyartistiderror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),
    // on(releaseActions.getreleasesbyrecordingid, (state) => ({
    //   ...state,
    //   releases: skeleton(4),
    //   status: 'loading' as Status
    // })),
    // on(releaseActions.getreleasesbyrecordingidsuccess, (state, {releases}) => ({
    //   ...state,
    //   releases,
    //   status: 'success' as Status
    // })),
    // on(releaseActions.getreleasesbyrecordingiderror, (state, {error}) => ({
    //   ...state,
    //   error,
    //   status: 'error' as Status
    // }))
  )
})
