import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, catchError, of, switchMap, tap, timer } from "rxjs";
import { workActions } from "./work.actions";
import { inject } from "@angular/core";
import { ApiSearchService } from "src/app/services/api-search.service";
import { ExplorerUtilsService } from "../../data/services/utils.service";
import { interpretError } from "../utils";

export class WorkEffects {
  actions$ = inject(Actions)
  apiSearchService = inject(ApiSearchService);
  utilsService = inject(ExplorerUtilsService);

  getWorksByArtistId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workActions.getworksbyartistid),
      switchMap((action) =>
        this.apiSearchService.lookupWorksByQuansicId(action.quansicId).pipe(
          map(works => workActions.getworksbyartistidsuccess({ works })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(workActions.getworksbyartistiderror({ error: interpretError(error) }))
          }))
      ),
    )
  )

  getWorkByBowi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workActions.getworkbybowi),
      switchMap((action) =>
        this.apiSearchService.lookupBowi$(action.bowi).pipe(
          map(xWork => workActions.getworkbyidsuccess({ xWork })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(workActions.getworkbyiderror({ error: interpretError(error) }))
          }))
      ),
    )
  )

  getWorkByIswc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workActions.getworkbyiswc),
      switchMap((action) =>
        this.apiSearchService.lookupIswc$(action.iswc).pipe(
          map(xWork => workActions.getworkbyidsuccess({ xWork })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(workActions.getworkbyiderror({ error: interpretError(error) }))
          }))
      ),
    )
  )

  getWorksByIsrc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workActions.getworksbyrecordingid),
      switchMap((action) =>
        this.apiSearchService.lookupWorksByRecordingId(action.isrc, action.offset).pipe(
          map(({ works, total }) => workActions.getworksbyrecordingidsuccess({ works, total })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(workActions.getworksbyrecordingiderror({ error }))
          }))
      ),
    )
  )
}
