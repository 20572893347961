import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { LoginService } from "@githubquansic/web-auth/ng";
import { Observable } from "rxjs";
import { CrtcService } from "./crtc/services/crtc.service";

export type XInstanceHeader = 'default' | 'crtc'

@Injectable()
export class GraphInstanceInterceptor implements HttpInterceptor {
  loginService = inject(LoginService)
  crtcService = inject(CrtcService)

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let xinstance: XInstanceHeader = 'default';
    if(this.crtcService.isCrtcUser()){
      xinstance = 'crtc'
    }
    const req = request.clone({
      headers: request.headers.set('xinstance', xinstance)
    })
    return next.handle(req);
  }
}
