<div class="entity-page">
  <div class="name-block">
    <h1>Artist:</h1>
    <div class="name">
      <h1><skeleton shape="small" ></skeleton></h1>
      <em class="comments"><skeleton shape="tiny" ></skeleton></em>
    </div>
  </div>
  <div class="info">
    <div class="link">
      <skeleton shape="circle"></skeleton>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="information">
      <h2>Information</h2>
      <div><span class="strong">Type:</span> <skeleton shape="tiny" ></skeleton></div>
      <div><span class="strong">Nationality:</span> <skeleton shape="tiny" ></skeleton></div>
      <div><span class="strong">Date of Birth:</span> <skeleton shape="tiny" ></skeleton></div>
      <div><span class="strong">Date of Death:</span> <skeleton shape="tiny" ></skeleton></div>
    </div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list two-columns">
        <div class="identifier isni"><span class="strong">ISNI:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier ipi"><span class="strong">IPI:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier ipn"><span class="strong">IPN:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier discogsId"><span class="strong">Discogs Id:</span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier musicbrainzId"><span class="strong">Musicbrainz Id:</span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier deezerId"><span class="strong">Deezer Id:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier luminateId"><span class="strong">Luminate Id:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier gracenoteId"><span class="strong">Gracenote Id:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier tmsId"><span class="strong">TMS Id:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier appleId"><span class="strong">Apple Id:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier spotifyId"><span class="strong">Spotify Id:</span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier wikidataId"><span class="strong">Wikidata Id:</span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier amazonId"><span class="strong">Amazon Id:</span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier mergedIsni"><span class="strong">Merged ISNI:</span> <skeleton shape="tiny" ></skeleton></div>
      </div>
    </div>
    <div class="spacer"></div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" disabled>
      <mat-tab position="1">
        <ng-template mat-tab-label>
          Relationships
        </ng-template>
        <div>
          <relationships ></relationships>
          <nameVariants ></nameVariants>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Releases</div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Recordings</div>

        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Works</div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
