<div class="recording">
    <h2>Recording: 
      <span style="font-weight: 300; margin-left: 10px">{{recording.id}}</span> 
      <span style="font-weight: 100; margin-left: 10px">({{recording.title}})</span>
    </h2>
    <div class="recording-table-container">
      <mat-table [dataSource]="recordingContribDS"
        *ngIf="recordingContribDS.data.length > 0; else noContributors" class="crtc-table">
        <ng-container matColumnDef="isni" >
          <mat-header-cell *matHeaderCellDef> ISNI </mat-header-cell>
          <mat-cell *matCellDef="let contributor">{{contributor.ids.isnis[0]}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name" >
          <mat-header-cell *matHeaderCellDef> Contributor </mat-header-cell>
          <mat-cell *matCellDef="let contributor">{{contributor.name}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="role" >
          <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
          <mat-cell *matCellDef="let contributor">
            {{contributor.contributorType}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nationality" >
          <mat-header-cell *matHeaderCellDef> Nationality (Source) </mat-header-cell>
          <mat-cell *matCellDef="let contributor">
            {{contributor.nationality}} <span *ngIf="contributor.nationalitySource">({{contributor.nationalitySource}})</span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row matRipple *matRowDef="let contributor; columns: columns;" ></mat-row>
      </mat-table>
    </div>
</div>
<ng-template #noContributors>
    No Contributors
  </ng-template>