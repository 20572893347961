import { Component, Input, inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, Subject, finalize, forkJoin } from "rxjs";
import { Artist, UserData } from "../model";
import { EmailService } from "../services/email.service";
import { LoginService } from "../services/login.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

interface FeedbackInterface {
  topics: TemplateInterface[];
}
interface TemplateInterface {
  id: string;
  label: string;
  description: string[];
}

export const artistFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Artist Information (birthdate, nationality etc)", description: []},
    {id: "identifiers", label: "Artist Identifiers", description: []},
    {id: "relationships", label: "Relationships", description: [""]},
    {id: "variants", label: "Name Variants", description: [""]},
    {id: "recordings", label: "Recordings", description: [""]},
    {id: "releases", label: "Releases", description: [""]},
    {id: "works", label: "Works", description: [""]}
  ]
};
export const recordingFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Recording Information", description: []},
    {id: "identifiers", label: "Recording Identifiers", description: []},
    {id: "contributors", label: "Recording Contributors", description: []},
    {id: "releases", label: "Related Releases", description: []},
    {id: "works", label: "Related Works", description: []}
  ]
};
export const releaseFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Release Information", description: []},
    {id: "contributors", label: "Release Contributors", description: []},
    {id: "recordings", label: "Related Recordings", description: []}
  ]
};
export const workFeedback: FeedbackInterface = {
  topics: [
    {id: "info", label: "Work Information", description: []},
    {id: "identifiers", label: "Work Identifiers", description: []},
    {id: "contributors", label: "Work Contributors", description: []},
    {id: "recordings", label: "Related Recordings", description: []}
  ]
};
export const disambigFeedback: FeedbackInterface = {
  topics: [
    {id: "missing", label: "Missing Artist", description: ["Tell us everything you know about the missing artist", "Name, date of birth, nationality, identifiers..."]},
    {id: "other", label: "Other", description: [ ]}
  ]
};

export const identifierFeedback: FeedbackInterface = {
  topics: [
    {id: "missing", label: "Missing Entity", description: ["Tell us everything you know about the missing entity"]},
    {id: "other", label: "Other", description: [ ]}
  ]
};

export enum FeedbackContext {
  UNKNOWN = 'unknown',
  NOT_FOUND = 'not-found',
  DISAMBIG = 'disambig',
  IDENTIFIER = 'identifier',
  ARTIST = 'artist',
  RELEASE = 'release',
  RECORDING = 'recording',
  WORK = 'work'
}

export type FeedbackContextData = {
  context: FeedbackContext;
  notFound?: {id: string; idType: string};
  artist?: {id: string; name: string};
  recording?: {isrc?: string; title?: string};
  release?: {upc?: string; title?: string};
  work?: {bowi?: string; iswc?: string; title?: string};
  disambig?: {searchTerm: string, artists: Artist[]};
}

@Component({
  templateUrl: './dialog-feedback.component.html',
  styleUrls: ['./dialog-feedback.component.scss']
})
export class DialogFeedbackComponent {
  loginService = inject(LoginService)
  emailService = inject(EmailService)
  data:FeedbackContextData = inject(MAT_DIALOG_DATA)

  FeedbackContext = FeedbackContext;

  feedbackForm: FormGroup = new FormGroup({
    company: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    purpose: new FormControl(''),
    message: new FormControl('', [Validators.required])
  });
  templateFrom: FormGroup = new FormGroup({});
  feedback!: FeedbackInterface;
  selectedTemplate!: TemplateInterface;
  contextDetails = "";
  msgPlaceholder = "";
  feedbackSent = false;
  feedbackFailed = false;
  purposes: string[] = [];
  nextId = 0;
  opened = false;
  sending = false;
  @Input() idModal: string|null = null;
  @Input() callback: ((formData: any) => Observable<any>)|null = null;

  destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    if (this.idModal == null) { this.idModal = `panel-feedback-${this.nextId++}`; }

    this.initFormFromUserData();
    this.initResults();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initFormFromUserData(): void {
    const userData = this.loginService.getUserData() as UserData;
    if (userData){
      this.feedbackForm.get('email')?.setValue(userData.email);
      this.feedbackForm.get('name')?.setValue(userData.name);
      this.feedbackForm.get('company')?.setValue(userData.company);
    }
  }

  initResults(): void {
    switch(this.data.context) {
      case FeedbackContext.NOT_FOUND:
        this.feedback = identifierFeedback;
        this.contextDetails = `Missing Entity. ${this.data?.notFound?.idType} -> ${this.data?.notFound?.id}`;
        break;
      case FeedbackContext.DISAMBIG:
        this.feedback = disambigFeedback;
        const artists = this.data.disambig?.artists.map((artist => `${artist.name} (${artist.id})}`)).join(" - ");
        this.contextDetails = `Name Disambig. Query: ${this.data?.disambig?.searchTerm} -> ${artists}`;
        break;
      case FeedbackContext.ARTIST:
        this.feedback = artistFeedback;
        this.contextDetails = `Artist QID: ${this.data?.context === this.data?.artist?.id} (${this.data?.artist?.name})`;
        break;
      case FeedbackContext.RECORDING:
        this.feedback = recordingFeedback;
        this.contextDetails = `Recording ISRC: ${this.data?.recording?.isrc} (${this.data?.recording?.title})`;
        break;
      case FeedbackContext.RELEASE:
        this.feedback = releaseFeedback;
        this.contextDetails = `Release UPC: ${this.data?.release?.upc} (${this.data?.release?.title})`;
        break;
      case FeedbackContext.WORK:
        this.feedback = workFeedback;
        this.contextDetails = `Work ${this.data?.work?.iswc || this.data?.work?.bowi} (${this.data?.work?.title})`;
        break;
    }
    this.feedbackForm.get('purpose')?.setValue(this.feedback?.topics[0].id);
    this.setMessagePlaceholder();
  }

  reset(): void{
    this.feedbackSent = false;
    this.feedbackFailed = false;
    this.feedbackForm.get('message')?.setValue(null);
  }

  submit(): void {

    const { email, name, company, context, purpose,message } = {
      email: this.feedbackForm.get('email')?.value,
      name: this.feedbackForm.get('name')?.value,
      company: this.feedbackForm.get('company')?.value,
      context: this.contextDetails,
      purpose: this.feedbackForm.get('purpose')?.value,
      message: this.feedbackForm.get('message')?.value
    }
    this.sending = true
    forkJoin([
      this.emailService.feedbackMessage({ email, name, company, context, purpose,message }),
      this.emailService.feedbackMessageInternal({ email, name, company, context, purpose,message })
    ])
    .pipe(
      finalize(() => this.sending = false)
    )
    .subscribe((result: any) => {
      if (result && result.every((r:{status: string}) => r.status === "OK")) {
        this.feedbackSent = true;
        this.feedbackFailed = false;
      } else {
        this.feedbackSent = false;
        this.feedbackFailed = true;
      }
    });
  }

  isCompanyInvalid(): boolean {
    return false;
  }

  isNameInvalid(): boolean {
    return false;
  }

  isEmailInvalid(): boolean{
    return false;
  }
  isEmailEmpty(): boolean {
    return false;
  }
  isEmailFormatInvalid(): boolean{
    return false;
  }

  closePanel(): void {
    this.feedbackSent = false;
    this.feedbackFailed = false;
    this.opened = false;
  }

  openPanel(): void {
    this.feedbackSent = false;
    this.feedbackFailed = false;
    this.opened = true;
  }

  setMessagePlaceholder(): void {
    const template = this.feedback?.topics.find((topic) => topic.id === this.feedbackForm.get('purpose')?.value);
    if (template) {
      this.msgPlaceholder = template.description !== null ? (template.description as string[]).join("\n") : "";
    }
  }
}
