import { Component, Input, inject } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { EMPTY, Observable, Subject, combineLatest, map, takeUntil, tap } from "rxjs";
import { Artist, Recording, Work, WorkOfRecording } from "src/app/model";
import { workActions } from "src/app/shared/store/work/work.actions";
import { workQuery } from "src/app/shared/store/work/work.selectors";
import { ContributorOfWork } from "../maipl-edit-panel.component";
import { recordingQuery } from "src/app/shared/store/recording/recording.selectors";

@Component({
    selector: 'work-contributors',
    templateUrl: './work-contributors.component.html',
    styleUrls: ['./work-contributors.component.scss']
})
export class WorkContributorsComponent {
    readonly store = inject(Store)
    
    @Input() recording!: Recording
    // work: WorkOfRecording|null = null;
    
    workContribDS: MatTableDataSource<ContributorOfWork> = new MatTableDataSource();
    worksColumns = ['isni', 'name', 'role', 'nationality']

    firstWorks$ = this.store.select(recordingQuery.selectWorks).pipe(
        map(works => works?.slice(0, 3)),
    )

    onDestroy$ = new Subject<void>()

   ngOnInit(){
    this.fetchPaginatedWorks();
   }

   ngOnDestroy() {
    this.onDestroy$.next();
   }

   offset = 0;
   workDataSource$ =      
   combineLatest([
        this.store.select(workQuery.selectWorkContributors),
        this.store.select(workQuery.selectWorkContributorsTotal)
    ]).pipe(
        takeUntil(this.onDestroy$),
        map(([workContributors, total]) => {
            return  workContributors !== null 
                ? workContributors.reduce((contributors: Artist[], curr: WorkOfRecording) => {    
                    if(curr.contributors) return contributors.concat(curr.contributors);
                    else return contributors
                }, [])
                : []
        }),
        map((contributors: Artist[]) => {
            return contributors.reduce((acc: Artist[], contributor: Artist) => {
                const existing = acc.find(c => 
                    c.ids.quansicId === contributor.ids.quansicId //same id
                    && c.role === contributor.role) //same role
                if(!existing) {
                    acc.push(contributor)
                }
                return acc;
              }, []);
        }),
        map((contributors: Artist[]) => {
            let dataSource = new MatTableDataSource<ContributorOfWork>();
            if(contributors) {
                dataSource.data = contributors.map((contrib: Artist) => {
                    const id = contrib.ids.isnis?.length > 0 ? contrib.ids.isnis[0] : ''
                    return {
                        id,
                        contributor: contrib
                    } as ContributorOfWork
                })
            }
            return dataSource;
        })
    )
    fetchPaginatedWorks(): void {
        this.store.dispatch(workActions.getworksbyrecordingid({isrc: this.recording.isrc, offset: this.offset}));
    }
}