import { createFeature, createReducer, on } from "@ngrx/store";
import { authActions } from "./auth.actions";

export interface AuthState {}

export const initialState: AuthState = {};

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer(
    initialState,
    on(authActions.verifysession, (state) => ({
      ...state,
    }))
  )
});
