import { Component, Input, OnInit} from '@angular/core';
import { ApiError} from "../model";
import { LoginService} from "../services/login.service";
import { Router} from "@angular/router";
import { DialogFeedbackComponent } from '../dialog-feedback/dialog-feedback.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.css']
})
export class PageErrorComponent implements OnInit {
  @Input() error: ApiError|null = null;
  @Input() errorMsg: string|null = null;
  @Input() identifiers: any;

  constructor(private loginService:LoginService, private router:Router, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  notFound() {
    return this.error != null && this.error == ApiError.NOT_FOUND;
  }
  invalidId() {
    return this.error != null && this.error == ApiError.INVALID_ID;
  }
  serverError() {
    return this.error != null && this.error == ApiError.SERVER_ERROR;
  }
  unauthorized() {
    return this.error != null && this.error == ApiError.UNAUTHORIZED;
  }

  isName() {
    return ["name"].includes(this.identifiers?.idType.toLowerCase());
  }
  isArtist() {
    return !["upc","isrc","iswc","bowi"].includes(this.identifiers?.idType.toLowerCase())
  }
  isRelease() {
    return ["upc"].includes(this.identifiers?.idType.toLowerCase())
  }
  isRecording() {
    return ["isrc"].includes(this.identifiers?.idType.toLowerCase())
  }
  isWork() {
    return ["iswc", "bowi"].includes(this.identifiers?.idType.toLowerCase())
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/app-login']);
  }

  openFeedbackDialog(): void {
    this.dialog.open(DialogFeedbackComponent, {
      panelClass: "dialog"
    });
  }
}
