import { releaseFeature } from "./release.reducer"

export const { selectReleases, selectRelease, selectRecordings, selectMainArtists, selectStatus, selectError } = releaseFeature;

export const releaseQuery = {
  selectReleases,
  selectRelease,
  selectRecordings,
  selectMainArtists,
  selectStatus,
  selectError
};
