import { recordingFeature } from "./recording.reducer"

export const { selectRecordings,
  selectRecording, selectRecordingsTotal,
  selectRecordingContributors, selectRecordingContributorsPage, selectRecordingContributorsTotal,
  selectReleases, selectWorks, selectArtists,
  selectStatus, selectContributorsStatus, selectError
} = recordingFeature;

export const recordingQuery = {
  selectRecordings,
  selectRecordingsTotal,
  selectRecordingContributors,
  selectRecordingContributorsPage,
  selectRecordingContributorsTotal,
  selectRecording,
  selectReleases,
  selectWorks,
  selectArtists,
  selectStatus,
  selectContributorsStatus,
  selectError
};
