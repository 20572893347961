<div class="entity-page">
  <div class="name-block">
    <h1>Work:</h1>
    <div class="name">
      <h1><skeleton shape="small"></skeleton></h1>
    </div>
  </div>
  <div class="info">
    <div class="picture">
      <div>
        <skeleton shape="circle"></skeleton>
      </div>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">BOWI:</span> <skeleton shape="small"></skeleton></div>
        <div class="identifier"><span class="strong">ISWC:</span> <skeleton shape="small"></skeleton></div>
      </div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Contributors">
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div>Recordings</div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
