import { Release, XAPIRelease } from "src/app/model";

export const toReleaseFromXApiRelease = (xRelease: XAPIRelease): Release => {
  return {
      upc: xRelease.upc,
      entityType: 'release',
      type: xRelease.type,
      title: xRelease.title,
      year: xRelease.year,
      cover: xRelease.cover,
      label: xRelease.label,
      active: xRelease.active
  } as Release;
}
