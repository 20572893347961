import { XAPIRecording, YSources, Recording, Release, XAPIRelease, Work, WorkOfRecording, XAPIWork, RecordingOfWork } from "src/app/model";
import { toWorkFromXApiWork } from "../work/work.domain";
import { toArtistFromXParty } from "../artist/artist.domain";

export const toRecordingFromXAPIRecording = (xRecording: XAPIRecording, sources?: YSources): Recording => {
    //recording.crtcMaplStatus = {isCanadianContent: true, classifications: {"Quansic": "MAP"}, source: "test"}
  return {
    id: xRecording.isrc,
    entityType: "recording",
    isrc: xRecording.isrc,
    appleId: xRecording.appleId,
    spotifyId: xRecording.spotifyId,
    title: xRecording.title,
    subtitle: xRecording.subtitle,
    year: xRecording.year,
    country: xRecording.country,
    contributors: xRecording.contributors !== undefined ? xRecording.contributors ?.map(p => toArtistFromXParty(p)) : [],
    mainArtists: xRecording.contributors ?.map(p => toArtistFromXParty(p)), //for display Artist column in table recordings
    contributorType: xRecording.contributorType,
    role: xRecording.role,
    duration: xRecording.durationMs,
    audioUrl: xRecording.audioUrl,
    pLine: cleanPLineString(xRecording.pLine || ""),
    active: xRecording.active,
    crtcMaiplStatus: xRecording.crtcMaiplStatus,
    crtcPropertiesStatus: xRecording.crtcPropertiesStatus,
    ySources: sources
  } as Recording;
}

export const toReleasesFromXApiRecording = (xApiRecording: XAPIRecording):Release[] => {
  if (xApiRecording.releases !== undefined)
    return xApiRecording.releases.map((release:XAPIRelease) => {
      return {
        upc: release.upc,
        entityType: 'release',
        type: release.type,
        title: release.title,
        country: "",
        year: release.year,
        cover: release.cover
      } as Release;
    })
  else return []
}

export const toWorksOfRecordingFromXAPIWorks = (xApiWorks: XAPIWork[]): WorkOfRecording[] => {
  return xApiWorks.map(xWork => toWorkFromXApiWork(xWork)).map((work: Work, idx: number) => {
    return {q2Score: xApiWorks[idx].q2Score, ...work} as WorkOfRecording
  })
}

const cleanPLineString = (pLine: string): string => {
  return pLine.replace("(P)", "").replace('\u24C5', "").replace('℗', "");
}

export const toRecordingsOfWorkFromXAPIRecording = (xRecordings: XAPIRecording[]): RecordingOfWork[] => {
  return xRecordings
    .map(xRecording => toRecordingFromXAPIRecording(xRecording))
    .map(recording => ({
      ...recording,
      contributors: recording.contributors === undefined ? [] : recording.contributors
    }))
    .map((recording: Recording, idx: number) => ({q2Score: xRecordings[idx].q2Score, ...recording} as RecordingOfWork))
}
