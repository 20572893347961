import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ProgressService} from "../services/progress.service";

@Component({
  selector: 'audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements AfterViewInit {
  //@ts-ignore
  @ViewChild('audioPlayer') audioPlayer: ElementRef;
  @Input() audioUrl:string = "";
  @Input() trackIsrc:string = "";
  @Input() trackName:string = "";
  @Input() format:'mini'|'full' = 'mini';

  @ViewChild('container',{ static: false }) container!: ElementRef;

  url: string|null = null;

  stroke = 2;
  playback = {
    radius: 0,
    normalizedRadius: 0,
    circumference: 0,
    offset: 0,
    percent: 0
  }

  // reference : https://css-tricks.com/building-progress-ring-quickly/

  //Audio
  isLoading = false;
  isPlaying = false;
  isEmpty = false;
  currentTime = 0.0;
  totalTime = 0.0;

  constructor(private progressService:ProgressService) {
    this.progressService.getPlaybackTopic().subscribe((progress) => {
      if(progress.trackIsrc != this.trackIsrc){
        this.stop();
      }
    })
  }

  ngAfterViewInit() {
    if(this.audioUrl === "" || this.audioUrl === null) {
      this.isEmpty = true;
    }

    if(this.format === "full") {
      this.stroke = 6;
    }
  }

  initPlayback() {
    // this.playback.radius = (this.container.nativeElement.offsetWidth / 2 ) * 0.95;
    this.playback.radius = (this.container.nativeElement.offsetWidth / 2 );
    if(this.format === 'mini') this.playback.radius = this.playback.radius * 0.7;
    if(this.format === 'full') this.playback.radius = this.playback.radius * 0.9;
    // this.playback.normalizedRadius = this.playback.radius - this.stroke * 2;
    //this.playback.circumference = Math.round(this.playback.normalizedRadius * 2 * Math.PI);// * 1.3;
    this.playback.circumference = Math.round(this.playback.radius * 2 * Math.PI);// * 1.3;
    this.setPlaybackProgress(0);

    this.totalTime = 35
    this.audioPlayer.nativeElement.ondurationchange = (event:any) => {
      this.totalTime = parseFloat(this.audioPlayer.nativeElement.duration)
    }
    this.audioPlayer.nativeElement.ontimeupdate = (event:any) => {
      this.currentTime = parseFloat(this.audioPlayer.nativeElement.currentTime);
      const progress = (this.currentTime / this.totalTime) * 100;
      this.setPlaybackProgress(Math.round(progress));
    }
    this.audioPlayer.nativeElement.onended = () => {
      this.stop();
    }
  }


  setPlaybackProgress(percent:number) {
    this.playback.percent = percent;
    this.playback.offset = Math.round(this.playback.circumference) - Math.round((percent / 100 * this.playback.circumference))
  }

  toggleAudio() {
    if(this.isEmpty) return;
    this.isPlaying = !this.isPlaying;

    if(this.url === null){
      this.url = this.audioUrl;
      this.audioPlayer.nativeElement.onloadeddata = () => {
        this.initPlayback();
        this.play();
      }
      return;
    }

    if(this.isPlaying){
      this.play();
    } else {
      this.pause();
    }
  }


  play(){
    this.progressService.startAudioPlayback(this.trackIsrc);
    this.audioPlayer.nativeElement.play().then(() => {
      this.isPlaying = true
    });
  }
  pause() {
    this.audioPlayer.nativeElement.pause();
  }
  stop() {
    if(this.isPlaying) {
      this.audioPlayer.nativeElement.pause();
      this.audioPlayer.nativeElement.currentTime = 0;
      this.setPlaybackProgress(0);
      this.isPlaying = false;
    }
  }
}
