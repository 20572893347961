import { DialogUtilsService } from './../shared/data/services/dialog-utils.service';
import { CatalogService } from '../services/firestore/catalog.firestore.service';
import { DialogHelpComponent } from '../dialog-help/dialog-help.component';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router} from "@angular/router";
import { EmailService} from "../services/email.service";
import { ExplorerRole} from '@githubquansic/auth/built/client';
import { LoginService, UserData } from '@githubquansic/web-auth/ng';
import { Observable, of} from "rxjs";
import { environment } from "../../environments/environment";
import { MatDialog } from '@angular/material/dialog';
import { DialogContactComponent } from '../dialog-contact/dialog-contact.component';
import { DialogPolicyComponent } from '../dialog-policy/dialog-policy.component';
import { DialogTermsComponent } from '../dialog-terms/dialog-terms.component';
import { DialogFeedbackComponent, FeedbackContext } from '../dialog-feedback/dialog-feedback.component';
import { DOCUMENT } from '@angular/common';
import { InlineEditService } from '../services/inline-edit.service';
import { ResultService } from '../services/result.service';
import { CrtcService } from '../crtc/services/crtc.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  dialogUtilsService = inject(DialogUtilsService)

  userData: UserData|null = null;
  version: string = environment.version;
  isDemo = false;
  isDataTest = false;
  isDev = false;
  isStaging = false;

  shouldDisplay = true;

  constructor(@Inject(DOCUMENT) private document: Document,
    private loginService: LoginService,
    private inlineEditService: InlineEditService,
    private crtcService: CrtcService,
    public catalogService: CatalogService,
    public resultService: ResultService,
    private emailService: EmailService,
    private router: Router,
    private route: ActivatedRoute) {
    this.userData = this.loginService.getUserData() ;
    this.isDemo = environment.environmentName === "demo";
    this.isDataTest = environment.environmentName === "data-test";
    this.isDev = environment.environmentName === "development";
    this.isStaging = environment.environmentName === "staging";

    this.evaluateContext();
  }

  evaluateContext() {
    this.shouldDisplay = !(this.route.snapshot.url[0]?.path === "home" && this.route.snapshot.url[1]?.path === "any")
  }

  openContactUsDialog(): void {
    this.dialogUtilsService.openContactUsDialog();
  }

  openPrivacyPolicyDialog(): void {
   this.dialogUtilsService.openPrivacyPolicyDialog();
  }

  openTermsConditionsDialog(): void {
    this.dialogUtilsService.openTermsConditionsDialog();
  }

  openHelpDialog(): void {
    this.dialogUtilsService.openHelpDialog();
  }

  openFeedbackDialog(): void {
    this.dialogUtilsService.openFeedbackDialog();
  }


  logout(): void {
    this.loginService.logout().subscribe();
    this.router.navigate(['/app-login']);
  }

  contactUsCallback(contactData: any): Observable<any> {
    return this.emailService.contactMessage(contactData);
  }

  navigateToLegacyApplication(){
    let path = this.route.snapshot.url.map((seg) => seg.path).join('/');
    const url = new URL(path, environment.legacyDomain);
    this.document.location.href = url.toString();
  }

  isEditModeEnabled() {
    return this.loginService.getUserData()?.rights.explorerRoles.includes(ExplorerRole.Writer)
  }

  isCrtcUser() {
    return this.crtcService.isCrtcUser();
  }

  toggleEditMode(){
    if(this.inlineEditService.editMode) {
      this.inlineEditService.openConfirmDialog()
    } else this.inlineEditService.enableEditMode();
  }

  isEditModeSliderChecked$ = this.inlineEditService.editMode$
  isEditModeSliderDisabled$ = of(false);//this.catalogService.isCurrentInCatalog$.pipe(map(isInCatalog => !isInCatalog));
}
