<div class="properties" [formGroup]="formGroup">
    <div>
      <div>
        <mat-form-field style="width: 300px;">
          <mat-label>Language ISO 6392</mat-label>
          <mat-select formControlName="language" >
            <mat-option [value]="lang.iso6392" *ngFor="let lang of languageList">{{lang.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 300px;">
          <mat-label>Sub-Category </mat-label>
          <mat-select formControlName="subCategory">
            <mat-optgroup *ngFor="let category of categoriesList" [label]="category.label">
              <mat-option *ngFor="let subCategory of category.subCategories;" [value]="subCategory.subCategoryId">
                {{subCategory.subCategoryId}} - {{subCategory.label}}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
        <mat-label>Is Emerging ?<span *ngIf="isEmergingNotSet()">(not set)</span></mat-label>
        <div class="isEmerging" *ngIf="!isEmergingNotSet()">
          <span style="cursor: pointer" (click)="setIsEmerging(false)">False</span>
          <mat-slide-toggle formControlName="isEmerging" color="accent"></mat-slide-toggle>
          <span style="cursor: pointer" (click)="setIsEmerging(true)">True</span>
        </div>
        <div *ngIf="isEmergingNotSet()">
          <a (click)="setIsEmerging(true)">Set is emerging</a>
        </div>
      <span></span>
    </div>
  </div>