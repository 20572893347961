import { createFeature, createReducer, on } from "@ngrx/store";
import { Artist, Recording, RecordingContributor, Release, Work } from "src/app/model";
import { Status } from "../state";
import { recordingActions } from "./recording.actions";
import { toRecordingFromXAPIRecording, toReleasesFromXApiRecording, toWorksOfRecordingFromXAPIWorks } from "./recording.domain";
import { toArtistFromXParty } from "../artist/artist.domain";

export interface RecordingState {
  recordings: Recording[]|null;
  recordingsTotal: number|null;
  recordingContributors: RecordingContributor[]|null,
  recordingContributorsPage: RecordingContributor[]|null,
  recordingContributorsTotal: number|null;
  recording: Recording|null;
  releases: Release[]|null;
  works: Work[]|null;
  artists: Artist[];
  status: Status;
  contributorsStatus: Status;
  error: string|undefined;
}
export const initialState: RecordingState = {
  recordings: null,
  recordingsTotal: null,
  recordingContributors: null,
  recordingContributorsPage: null,
  recordingContributorsTotal: null,
  recording: null,
  releases: [],
  works: null,
  artists: [],
  status: 'idle',
  contributorsStatus: 'idle',
  error: undefined
}

export const recordingFeature = createFeature({
  name: 'recording',
  reducer: createReducer(
    initialState,
    /* Recordings by isrc */
    on(recordingActions.getrecordingbyisrc, (state, {isrc}) => ({
        ...state,
        recording: null,
        recordings: null,
        status: 'loading' as Status
      })
    ),
    on(recordingActions.getrecordingbyisrcsuccess, (state, {xRecording, ySources}) => ({
      ...state,
      recording: toRecordingFromXAPIRecording(xRecording, ySources),
      artists: xRecording.contributors ? xRecording.contributors.map(xParty => toArtistFromXParty(xParty)): [],
      releases: toReleasesFromXApiRecording(xRecording),
      works: toWorksOfRecordingFromXAPIWorks(xRecording.works || []),
      status: 'success' as Status
    })),

    /* Recordings by artist id */
    on(recordingActions.getrecordingbyisrcerror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),
    on(recordingActions.getrecordingsbyartistid, (state) => ({
      ...state,
      recordings: null,
      status: 'loading' as Status
    })),
    on(recordingActions.getrecordingsbyartistidsuccess, (state, {}) => ({...state,
      recordingContributors: null,
      // recordings: xrecordings.map(r => toRecordingFromXAPIRecording(r)),
      status: 'success' as Status
    })),
    on(recordingActions.getrecordingsbyartistiderror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),



    /* Recording contributors paginated */
    on(recordingActions.getrecordingcontributorsbyartistid, (state) => {
      return ({
        ...state,
        recordingContributors: null,
        recordingContributorsTotal: 0,
        contributorsStatus: 'loading' as Status
      })
    }),
    on(recordingActions.getrecordingcontributorspagebyartistid, (state) => ({
      ...state,
      contributorsStatus: 'loading' as Status
    })),
    on(recordingActions.getrecordingcontributorspagebyartistidsuccess, (state, {contributors}) => {
      return ({
        ...state,
        recordingContributorsPage: [...contributors],
        recordingContributors: [...contributors].concat([...state.recordingContributors||[]]),
      })
    }),
    on(recordingActions.getrecordingcontributorspagebyartistiderror, (state, {error}) => ({
      ...state,
      error,
      contributorsStatus: 'error' as Status
    })),
    on(recordingActions.getrecordingcontributorspagebyartistidcomplete, (state) => ({
      ...state,
      contributorsStatus: 'success' as Status
    })),

    /* Recording paginated */
    on(recordingActions.getrecordingscountbyartistidsuccess, (state, {total}) => ({
      ...state,
      recordingsTotal: total
    })),
    on(recordingActions.getrecordingspagebyartistid, (state) => ({
      ...state,
      status: 'loading' as Status,
    })),
    on(recordingActions.getrecordingspagebyartistidsuccess, (state, {recordings}) => {
      if(recordings.length === 0) return {
        ...state,
        status: 'success' as Status
      }

      const recordingsToUpdate = [...state.recordings || [], ...recordings]
      // console.log("duplicates", findDuplicates(state.recordings  || []))
      return ({
        ...state,
        recordings: recordingsToUpdate,
        status: recordingsToUpdate.length >= (state.recordingsTotal || 0) ? 'success' : state.status
      })
    }),
    on(recordingActions.getrecordingspagebyartistiderror, (state, {error}) => ({
      ...state,
      error,
      status: 'error' as Status
    })),
    on(recordingActions.resetrecordings, (state) => {
      return ({
        ...initialState,
        recordings: null,
        recordingsTotal: null,
        recordingContributors: null,
        recordingContributorsPage: null,
        recordingContributorsTotal: null,
        recording: null,
        status: 'idle' as Status,
        contributorsStatus: 'idle' as Status,
      })
    }),
    on(recordingActions.editrecording, (state, {recording}) => {
      console.log("editrecording", recording)
      return {
        ...state,
        recording
      }
    })
  )
})


const findDuplicates = (recordings: Recording[]) => {
  const recMap: { [key: string]: number } = {};
  const duplicates: Recording[] = [];

  for(const rec of recordings) {
    if(recMap[rec.isrc]) {
      recMap[rec.isrc]++
    } else {
      recMap[rec.isrc] = 1;
    }
  }

  for(const rec of recordings) {
    if(recMap[rec.isrc] > 1) {
      duplicates.push(rec)
    }
  }

  return duplicates;
}


const removeDuplicates = (recordings: Recording[]) => {
  const uniqueIsrcs = new Set<string>();
  const uniqueRecordings: Recording[] = [];

  for (const rec of recordings) {
    if (!uniqueIsrcs.has(rec.isrc)) {
      uniqueIsrcs.add(rec.isrc);
      uniqueRecordings.push(rec);
    }
  }

  return recordings;
}


