import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Artist, Recording } from "src/app/model";

@Component({
    selector: 'recording-contributors',
    templateUrl: './recording-contributors.component.html',
    styleUrls: ['./recording-contributors.component.scss']
})
export class RecordingContributorsComponent implements OnInit {
    @Input() recording!: Recording
    recordingContribDS: MatTableDataSource<Artist> = new MatTableDataSource();

    columns = ['isni', 'name', 'role', 'nationality']

    ngOnInit(){
        const contributors = [...this.recording.contributors || []].filter(contributor => 
            contributor.contributorType === "MainArtist"
        )
        const sortedContributors = contributors.sort((a, b) => a.name.localeCompare(b.name))
        this.recordingContribDS = new MatTableDataSource(sortedContributors)
    }
}