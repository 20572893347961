import { LicenseService, LicenseInfo } from '../services/license.service';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageService } from "../services/image.service";

export type CoverData = {
  title: string
  cover: string,
  originalUrl: string
}
@Component({
  selector: 'dialog-cover',
  templateUrl: "./dialog-cover.component.html",
})
// <p style='padding-left: 0px'>This file is licensed under the <a href='https://en.wikipedia.org/wiki/en:Creative_Commons' target='_blank'>Creative Commons</a> Attribution-Share Alike 3.0 Unported license.</p> \
export class DialogCoverComponent {
  licenseInfo: LicenseInfo|null = null;
  title!: string;

  constructor(
    public dialogRef: MatDialogRef<DialogCoverComponent>,
    public imageService: ImageService,
    public licenseService: LicenseService,
    @Inject(MAT_DIALOG_DATA) public coverData: CoverData) {

      this.licenseService.getImageLicence$(coverData.originalUrl).subscribe({
        next: (licenseInfo: LicenseInfo|null) => {
          this.licenseInfo = licenseInfo;
        }
      })
      this.title = coverData.title;
    }

    isWikimediaImage(url: string): boolean {
      return this.imageService.isWikimediaImage(url);
    }
}
