import { ApiSearchService } from 'src/app/services/api-search.service';
import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ExplorerUtilsService } from "../../data/services/utils.service";
import { switchMap, catchError, of, map } from "rxjs";
import { authActions } from "./auth.actions";
import { Router } from '@angular/router';

export class AuthEffects {
  actions$ = inject(Actions)
  router = inject(Router);
  apiSearchService = inject(ApiSearchService);
  utilsService = inject(ExplorerUtilsService);

  verifySession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.verifysession),
      switchMap(() =>
        this.apiSearchService.verifySession().pipe(
          map(() => authActions.verifysessionsuccess()),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(authActions.verifysessionfailure({ error }));
          }))
      ),
    )
  )
}
