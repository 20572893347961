import { LoginService } from '@githubquansic/web-auth/ng';
import { HttpErrorResponse } from "@angular/common/http"
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  'providedIn': 'root'
})
export class ExplorerUtilsService {
  router = inject(Router)
  loginService = inject(LoginService)

  public interceptAuthError (error: HttpErrorResponse): void {
    if(error === null || error === undefined) return;
    if(error.status){
      switch (error.status){
        case 401:
        case 403:
          this.loginService.logout().subscribe();
          this.router.navigate(['/app-login']);
      }
    }
  }


}
