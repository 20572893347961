<ng-container *ngIf="releaseStatus$|async as status">
  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="releaseError$|async as error">
      <ng-container *ngIf="searchParams$|async as upc">
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND)">
          We cannot find a recording with this identifier (upc = {{upc}}).
          Please verify the identifier in the search or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">
          There has been a problem during the search.
          It may just be a blip, try again or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-release></skeleton-release>
  </ng-container>

  <ng-container *ngIf="status === 'success'">
    <ng-container *ngIf="release$|async as release">
      <div class="entity-page">
        <div class="name-block">
          <h1>Release:</h1>
          <div class="name">
            <h1>{{release.title}}</h1>
          </div>
          <button mat-icon-button class="link export" (click)="exportRelease()" title="Download release data">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
        </div>
        <div class="info">
          <div class="picture">
            <div *ngIf="release.cover !== undefined" (click)="openCoverDialog()" class="link" (keyDown)="openCoverDialog()">
              <img class="circle-mask circle-mask-large" [src]="release.coverImage" alt="Release cover" />
            </div>
            <div *ngIf="release.cover === undefined" >
              <img class="circle-mask circle-mask-large" [src]="release.coverImage" alt="Release cover" />
            </div>
          </div>
          <div style="flex: 0 1 1em"></div>
          <div class="information">
            <h2>Information</h2>
            <div><span class="strong">Type:</span> {{release.type}}</div>
            <div><span class="strong">Year of Recording:</span> {{release.year}}</div>
            <div><span class="strong">Label of Recording:</span> {{release.label}}</div>
          </div>
          <div class="identifiers">
            <h2>Identifiers</h2>
            <div class="identifiers-list">
              <div class="identifier"><span class="strong">UPC:</span> {{release.upc}}</div>
            </div>
          </div>
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
          [selectedIndex]="tabActive">
            <mat-tab label="Contributors">
              <div class="relationships" *ngIf="mainArtists$|async as mainArtists">
                <div class="title">Main Artists</div>
                <div class="grid grid-four-col">
                  <party-link [party]="artist" *ngFor="let artist of mainArtists | sortBy:'asc':'name'"></party-link>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>Recordings</div>
                <div class="data-count">
                  (
                    <ng-container>
                      <span *ngIf="recordings$|async as recordings">{{recordings.length}}</span>
                    </ng-container>
                  )
                </div>
                </ng-template>
              <table-recordings [recordings$]="recordings$"></table-recordings>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
