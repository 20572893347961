
<div *ngIf="isDataTestEnvironment" style="display: flex;align-items: center;gap: 14px;">
    <ng-container *ngIf="lockStatus$|async as lockStatus">
        {{lockStatus}}
        <mat-icon inline="true" class="lock-icon">
        {{lockStatus === ArtistLockStatus.Locked? 'lock' : 'lock_open'}} </mat-icon>
        <div *ngIf="isDataTestEnvironment" class="lock-btn">
        <button mat-raised-button class="link lock-btn"
            (click)="openLockDialog(lockStatus)" title="Lock/Unlock artist data">
            <span *ngIf="lockStatus === ArtistLockStatus.Locked">{{ArtistLockAction.Unlock}}</span>
            <span *ngIf="lockStatus === ArtistLockStatus.Unlocked">{{ArtistLockAction.Lock}}</span>
        </button>
        </div>
    </ng-container>
</div>