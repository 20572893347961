import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MaiplScoreChanges, MaiplScoreOverrides, MaiplScoreRuleChangeRequest, MaiplScoreRuleResult, rules } from "./maipl-score-rules";
import { MaiplCategory } from "src/app/crtc/services/crtc-model";



@Injectable({
    providedIn: 'root'
})
export class MaiplScoreRulesService{
    private overrides: MaiplScoreOverrides = {
        music: false,
        artist: false,
        instrument: false,
        performance: false,
        lyrics: false,
        isCancon: false,
    }
    
    private changeRequestsToApply: MaiplScoreRuleChangeRequest[]  = [];

    overrideScore(maiplCategory: MaiplCategory) {
        this.overrides[maiplCategory] = true;
    }
    overrideIsCancon() {
        this.overrides.isCancon = true;
    }

    resetOverrides() {
        this.overrides = {
            music: false,
            artist: false,
            instrument: false,
            performance: false,
            lyrics: false,
            isCancon: false,
        }
    }

    applyRules(formGroup: FormGroup): MaiplScoreRuleResult{
        let result: MaiplScoreRuleResult = {rules: [], changeRequests: []};
        rules.forEach(rule => {
            const shouldApply = rule.predicate(formGroup, this.overrides);
            if(shouldApply) {
                rule.apply(formGroup);
                this.applyChangeRequest(rule.changeRequest)
                result.rules = [...result.rules, {
                    id: rule.id,
                    description: rule.description,
                    isApplied: shouldApply,
                }];
            }
        })
        result.changeRequests = this.changeRequestsToApply;
        return result;
    }

    applyChangeRequest(changeRequest: MaiplScoreRuleChangeRequest|null) {
        if(changeRequest) {
            const changeRequestExisting = this.changeRequestsToApply.find(cr => cr.property === changeRequest.property);
            if(changeRequestExisting) {
                changeRequestExisting.originalValue = changeRequest.originalValue;
                changeRequestExisting.newValue = changeRequest.newValue;
            } else {
                this.changeRequestsToApply = [...this.changeRequestsToApply, changeRequest];
            }
        }
    }
}