import { Injectable, inject } from "@angular/core";
import { ExportService, JSON_EXTENSION, JSON_TYPE, RECORDING_HEADERS } from "../../services/export.service";
import { Artist, NameVariant, Recording, Relationship } from "../../model";
import { CrtcService } from "./crtc.service";

@Injectable({
  providedIn: 'root'
})
export class ExportCRTCService extends ExportService {
  crtcService = inject(CrtcService);

  constructor() {
    super()
  }

  override recordingInformationToArray(recording: Recording) {
    const crtcInfo = recording.crtcMaiplStatus
    const maiplScore = this.crtcService.getMaiplScore(crtcInfo);
    return [
      ["Title",               recording.title],
      ["Subtitle",            recording.subtitle],
      ["Duration",            this.durationPipe.transform(recording.duration)],
      ["Year of Recording",   recording.year?.toString()],
      ["Country of Recording",recording.country],
      ["P line",              recording.pLine],
      ["MAIPL Score",         maiplScore.code],
      ["Is Canadian Content", crtcInfo?.isCanadianContent.toString()]
    ]
  }

  override mapRecordings(recordings: Recording[]) {
    const result = recordings.map((rec) => {
      const crtcInfo = rec.crtcMaiplStatus
      const maiplScore = this.crtcService.getMaiplScore(crtcInfo);
      return [rec.isrc, rec.appleId, rec.spotifyId, rec.title, rec.subtitle, this.durationPipe.transform(rec.duration), this.getMainArtists(rec), this.getPerformers(rec),
      maiplScore.code, crtcInfo?.isCanadianContent.toString()];
    });
    result.unshift([...RECORDING_HEADERS, 'MAIPL Score', 'Is Canadian Content']);
    return result;
  }

  override exportPartyAsJson(party: Artist, nameVariants: NameVariant[], relationships: Relationship[], releases:any[], recordings:any[], works: any[], excelFileName: string): void {
    const exportJson = {
      ...party,
      nameVariants,
      relationships,
      releases,
      recordings,
      works
    }
    ExportService.saveAsFile(JSON.stringify(exportJson, null, 4), party.name, JSON_TYPE, JSON_EXTENSION);
  }
}
