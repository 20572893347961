<mat-table [dataSource]="datasource" matSort>
  <ng-container matColumnDef="audio" >
    <mat-header-cell *matHeaderCellDef> Audio </mat-header-cell>
    <mat-cell *matCellDef="let recording">
      <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null" format="mini" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="q2">
    <mat-header-cell *matHeaderCellDef mat-sort-header="q2Score"> Q2 </mat-header-cell>
    <mat-cell *matCellDef="let recording">{{recording.q2Score}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="isrc" >
    <mat-header-cell *matHeaderCellDef> ISRC </mat-header-cell>
    <mat-cell *matCellDef="let recording">
      <skeleton shape="small"></skeleton>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
    <mat-cell *matCellDef="let recording">
      <skeleton shape="autosm"></skeleton>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="year" >
    <mat-header-cell *matHeaderCellDef> Release Year </mat-header-cell>
    <mat-cell *matCellDef="let recording">
      <skeleton shape="small" *ngIf="recording.skeleton"></skeleton>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="artist" >
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      Artist / Role
    </mat-header-cell>
    <mat-cell *matCellDef="let recording">
      <skeleton shape="small"></skeleton>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
  <mat-row matRipple *matRowDef="let client; columns: columns;" ></mat-row>
</mat-table>
