import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: 'skeleton-work',
  templateUrl: './skeleton-work.component.html',
  styleUrls: ['./skeleton-work.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonWorkComponent {

}
