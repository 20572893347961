import { Artist, Entity, EntityType, Recording, Release, Work } from './../../../model';
import { Injectable, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  titleService:Title = inject(Title);

  changeBrowserTitle(entityType: EntityType, entity: Entity|null): void {
    let title = "Data-Explorer by Quansic";
    if(entity !== null) {
      switch(entityType){
        case 'party':
          title = `${(entity as Artist).name} - ${title}`; break;
        case 'recording':
          title = `${(entity as Recording).title} - ${title}`; break;
        case 'release':
          title = `${(entity as Release).title} - ${title}`; break;
        case 'work':
          title = `${(entity as Work).title} - ${title}`; break;
      }
    }
    this.titleService.setTitle(title);
  }
}
