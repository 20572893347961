import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: 'skeleton-release',
  templateUrl: './skeleton-release.component.html',
  styleUrls: ['./skeleton-release.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonReleaseComponent {

}
