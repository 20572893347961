<div class="mat-typography">
  <h1 mat-dialog-title>
    {{data.lockAction}} {{data.artist.name}}</h1>
  <div mat-dialog-content>
    <form [formGroup]="form">
      <div style="display: grid; gap: 10px;">
        <label>Comment</label>
        <mat-form-field appearance="fill">
          <textarea matInput rows="6" formControlName="comment"></textarea>
          <mat-error *ngIf="form.get('comment')?.errors?.['required']">The comment is mandatory</mat-error>
        </mat-form-field>
        <span *ngIf="httpError !== null" style="color: red;">{{httpError}}</span>
      </div>
    </form>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="accent" (click)="lock()" *ngIf="data.lockAction === ArtistLockAction.Lock">{{data.lockAction}}</button>
    <button mat-raised-button color="accent" (click)="unlock()" *ngIf="data.lockAction === ArtistLockAction.Unlock">{{data.lockAction}}</button>
  </div>
</div>
