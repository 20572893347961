import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, catchError, of, switchMap, tap, timer } from "rxjs";
import { inject } from "@angular/core";
import { ApiSearchService } from "src/app/services/api-search.service";
import { releaseActions } from "./release.actions";
import { ExplorerUtilsService } from "../../data/services/utils.service";
import { interpretError } from "../utils";

export class ReleaseEffects {
  actions$ = inject(Actions)
  apiSearchService = inject(ApiSearchService);
  utilsService = inject(ExplorerUtilsService);

  getReleasesByArtistId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(releaseActions.getreleasesbyartistid),
      switchMap((action) =>
        this.apiSearchService.lookupReleasesByQuansicId(action.quansicId).pipe(
          map(releases => releaseActions.getreleasesbyartistidsuccess({ releases })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(releaseActions.getreleasesbyartistiderror({ error }))
          }))
      ),
    )
  )

  getReleasesByUpc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(releaseActions.getreleasebyupc),
      switchMap((action) =>
        this.apiSearchService.lookupReleaseByUpc$(action.upc).pipe(
          map(xrelease => releaseActions.getreleasebyupcsuccess({ xrelease })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(releaseActions.getreleasesbyartistiderror({ error: interpretError(error) }))
          }))
      ),
    )
  )
}
