import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { tap } from "rxjs";
import { artistActions } from "src/app/shared/store/artist/artist.actions";
import { ArtistState } from "src/app/shared/store/artist/artist.reducer";
import { selectNameVariants, selectNameVariantsStatus } from "src/app/shared/store/artist/artist.selectors";

@Component({
  selector: 'nameVariants',
  templateUrl: './nameVariants.component.html'
})
export class NameVariantsComponent implements OnChanges {
  store = inject(Store<ArtistState>)

  @Input() quansicId!: string;

  nameVariants$ = this.store.select(selectNameVariants);
  status$ = this.store.select(selectNameVariantsStatus);

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['quansicId']) {
      this.store.dispatch(artistActions.getnamevariants({quansicId: this.quansicId}))
    }
  }
}
