
<ng-container *ngIf="artistStatus$|async as status">

  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="artistError$|async as error">
      <ng-container *ngIf="searchParams$|async as searchParams">
        <h2 *ngIf="error === ApiError.INVALID_ID && searchParams.quansicId">
          We cannot find an artist with this identifier (id = {{searchParams.quansicId}}).
          Please verify the identifier in the search or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND) && searchParams.idType">
          We cannot find an artist with this identifier ({{searchParams.idType}} = {{searchParams.id}}).
          Please verify the identifier in the search or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">There has been a problem during the search.
          It may just be a blip, try again or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-artist></skeleton-artist>
  </ng-container>
  <ng-container *ngIf="status === 'success'">
    <div class="entity-page" *ngIf="artist$|async as artist">
        <div class="name-block">
          <h1>Artist:</h1>
          <div class="name">
            <h1>{{artist.name}}</h1>
            <em class="comments">{{artist.comments}}</em>
          </div>
          <button mat-icon-button class="link export" (click)="exportArtist()" title="Download artist data">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
          <div style="flex-grow: 1"></div>
          <artist-lock [artist$]="artist$"></artist-lock>
        </div>
        <div class="info">
          <div *ngIf="artist.cover !== undefined" (click)="openCoverDialog()" class="link" (keyDown)="openCoverDialog()">
            <img class="circle-mask circle-mask-large" [src]="artist.coverImage" alt="Artist" />
          </div>
          <div *ngIf="artist.cover === undefined" >
            <img class="circle-mask circle-mask-large" [src]="artist.coverImage" alt="Artist" />
          </div>
          <div style="flex: 0 1 1em"></div>
          <div class="information">
            <h2>Information</h2>
            <div><span class="strong">Type:</span> {{artist.type}}</div>
            <div *ngIf="artist.type == 'Person'"><span class="strong">Gender:</span> {{artist.gender}}</div>
            <div><span class="strong">Nationality:</span> {{artist.nationality}}</div>
            <div><span class="strong">Date of Birth:</span> {{artist.birthdate}}</div>
            <div><span class="strong">Date of Death:</span> {{artist.deathdate}}</div>
          </div>
          <div class="identifiers">
            <h2>Identifiers</h2>
            <div class="identifiers-list two-columns">
              <div class="identifier isni"><span class="strong">ISNI:</span> <artist-identifier [values]="artist.ids.isnis" linkTemplate="https://isni.org/__ID__" idName="ISNI"></artist-identifier></div>
              <div class="identifier ipi"><span class="strong">IPI:</span>  <artist-identifier [values]="artist.ids.ipis" idName="IPI"></artist-identifier></div>
              <div class="identifier ipn"><span class="strong">IPN:</span> <artist-identifier [values]="artist.ids.ipns" idName="IPN"></artist-identifier></div>
              <div class="identifier discogsId"><span class="strong">Discogs Id:</span>  <artist-identifier [values]="artist.ids.discogsIds" linkTemplate="https://www.discogs.com/artist/__ID__" idName="Discogs ID"></artist-identifier></div>
              <div class="identifier musicbrainzId"><span class="strong">Musicbrainz Id:</span>  <artist-identifier [values]="artist.ids.musicBrainzIds" linkTemplate="https://musicbrainz.org/artist/__ID__" idName="MusicBrainz ID"></artist-identifier></div>
              <div class="identifier wikidataId"><span class="strong">Wikidata Id:</span>  <artist-identifier [values]="artist.ids.wikidataIds" linkTemplate="https://www.wikidata.org/wiki/__ID__" idName="Wikidata ID"></artist-identifier></div>
              <div class="identifier luminateId"><span class="strong">Luminate Id:</span> <artist-identifier [values]="artist.ids.luminateIds" idName="Luminate ID"></artist-identifier></div>
              <div class="identifier gracenoteId"><span class="strong">Gracenote Id:</span> <artist-identifier [values]="artist.ids.gracenoteIds" idName="Gracenote ID"></artist-identifier></div>
              <div class="identifier tmsId"><span class="strong">TMS Id:</span> <artist-identifier [values]="artist.ids.tmsIds" idName="TMS ID"></artist-identifier></div>
              <div class="identifier appleId"><span class="strong">Apple Id:</span> <artist-identifier [values]="artist.ids.appleIds" linkTemplate="https://music.apple.com/artist/__ID__" idName="Apple ID"></artist-identifier></div>
              <div class="identifier spotifyId"><span class="strong">Spotify Id:</span>  <artist-identifier [values]="artist.ids.spotifyIds" linkTemplate="https://open.spotify.com/artist/__ID__" idName="Spotify ID"></artist-identifier></div>
              <div class="identifier deezerId"><span class="strong">Deezer Id:</span> <artist-identifier [values]="artist.ids.deezerIds" linkTemplate="https://www.deezer.com/fr/artist/__ID__" idName="Deezer ID"></artist-identifier></div>
              <div class="identifier amazonId"><span class="strong">Amazon Id:</span>  <artist-identifier [values]="artist.ids.amazonIds" linkTemplate="https://music.amazon.com/artists/__ID__" idName="Amazon ID"></artist-identifier></div>              
              <div class="identifier mergedIsni"><span class="strong">Merged ISNI:</span>  <artist-identifier [values]="artist.ids.mergedIsnis" linkTemplate="https://isni.org/__ID__" idName="Merged ISNI"></artist-identifier></div>
            </div>
          </div>
          <div class="spacer"></div>
          <!-- <div *ngIf="crtcService.isCrtcUser()">
            <changelog [entityId]="artist.ids.quansicId"></changelog>
          </div> -->
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
            [selectedIndex]="tabActive">
            <mat-tab position="1" >
              <ng-template mat-tab-label>
                Relationships
              </ng-template>
              <div>
                <relationships [quansicId]="artist.ids.quansicId"></relationships>
                <nameVariants [quansicId]="artist.ids.quansicId"></nameVariants>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>Releases</div>
                <div class="data-count" *ngIf="releasesStatus$|async as status">
                  (
                    <ng-container >
                      <skeleton shape="tiny" *ngIf="status === 'idle' || status === 'loading'"></skeleton>
                    </ng-container>
                    <ng-container *ngIf="status === 'success'">
                      <span *ngIf="releases$|async as releases">{{releases.length}}</span>
                    </ng-container>
                  )
                </div>
              </ng-template>
              <table-releases [quansicId]="artist.ids.quansicId"></table-releases>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>Recordings</div>
                <div class="data-count" *ngIf="recordingsStatus$|async as status">
                  (
                    <ng-container >
                      <skeleton shape="tiny" *ngIf="status === 'idle' || status === 'loading'"></skeleton>
                    </ng-container>
                    <ng-container *ngIf="status === 'success'">
                      <span *ngIf="recordings$|async as recordings">{{recordings.length}}</span>
                    </ng-container>
                  )
                </div>
              </ng-template>
              <table-recordings [quansicId]="artist.ids.quansicId"></table-recordings>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>Works</div>
                <div class="data-count" *ngIf="worksStatus$|async as status">
                  (
                  <ng-container >
                    <skeleton shape="tiny" *ngIf="status === 'idle' || status === 'loading'"></skeleton>
                  </ng-container>
                  <ng-container *ngIf="status === 'success'">
                    <span *ngIf="works$|async as works">{{works.length}}</span>
                  </ng-container>
                  )
                </div>
              </ng-template>
              <table-works [quansicId]="artist.ids.quansicId"></table-works>
            </mat-tab>
          </mat-tab-group>
          <div class="export">
            <button mat-icon-button class="link" (click)="openExportRowsDialog()" [disabled]="!canExport()" title="Download artist data">
              <mat-icon [inline]="true">arrow_circle_down</mat-icon>
            </button>
          </div>
        </div>
    </div>
  </ng-container>
</ng-container>
