import { YDBService } from './../../services/y-db.service';
import { DialogChangelogComponent } from './dialog-changelog/dialog-changelog.component';
import { Component, Input, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { filter, map, merge, Observable, tap } from 'rxjs';

@Component({
  selector: 'changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent {
  dialog = inject(MatDialog);
  ydbService = inject(YDBService);

  @Input() entityId!: string;

  changeRequestsCount$: Observable<number> = merge(
    this.ydbService.entityLoad$.pipe(map(() => [])), //clear the logs on entity load
    this.ydbService.changeLogs$
  ).pipe(
    // tap((result) => console.log(typeof result, result)),
    // map((result) => 0)
    map(changeLogs => changeLogs.filter(cl => cl.id === this.entityId)),
    filter(changeLogs => changeLogs.length > 0),
    map(changeLogs => changeLogs.length)
  );


  openChangelogDialog() {
    this.dialog.closeAll();
    this.dialog.open(DialogChangelogComponent,  {
      panelClass: "changelog-dialog",
      autoFocus: false,
      data: {
        entityId: this.entityId,
      }
    });
  }
}
