import { ApiSearchService } from './api-search.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, from, map, mergeMap, Subject, tap, throwError} from "rxjs";
import { DialogConfirmComponent } from "../edit/dialog-confirm/dialog-confirm.component";
import { EntityType, YSources } from '../model';
import { YDBService } from './y-db.service';
import { LoginService } from '@githubquansic/web-auth/ng';
import { v4 as uuid } from 'uuid';

export interface ChangeRequest extends CandidateChangeRequest {
  uuid: string
  comment: string|null
  source: string
  user: string | null
}
export interface CandidateChangeRequest {
  entityType: EntityType
  entityId: string
  entityName: string
  displayId: string
  property: string
  originalValue: string|string[]|boolean|null|undefined
  newValue: string|string[]|boolean|null|undefined
  ySources: YSources
}

export interface ChangeLog {
  "uuid": string,
  "requester": {
      "id": string,
      "company": string
  },
  "id": string,
  "field": {
      "name": string,
      "oldValue": string,
      "newValue": string
  },
  "ySources": YSources,
  history: ChangeLogEvent[]
}
export interface ChangeLogEvent {
  "status": string,
  "statusBy": string,
  "statusComment": string,
  "timestamp": string
}

export interface InlineEdit {
  property: string
}

@Injectable({
  providedIn: 'root'
})
export class InlineEditService {
  dialog = inject(MatDialog)
  snackbar = inject(MatSnackBar)
  apiSearchService = inject(ApiSearchService)
  editMode = true;

  loginService = inject(LoginService);
  public ydbService: YDBService = inject(YDBService);


  editMode$ = new BehaviorSubject<boolean>(this.editMode);
  changeRequests$ = new Subject<ChangeRequest[]>();
  inlineEditInProgress$ = new Subject<string>();

  changeRequests: ChangeRequest[] = [];

  constructor(){
    this.enableEditMode();
  }

  pushChangeRequestCandidate(changeRequestCandidate: CandidateChangeRequest, graphValue: any): ChangeRequest|null {
    // If a change request already exists for this property and entity, it's now obsolete, remove the other CR 
    // before inserting the new one
    const existingCR: ChangeRequest|undefined = this.findSimilarChangeRequest(changeRequestCandidate);
    if(existingCR) this.discardChangeCandidate(existingCR.uuid);

    //If this change request is a noop (the value inserted is the same as the original value), discard it
    const noopCR: boolean = this.isNoopChangeRequest(changeRequestCandidate, graphValue);
    if(noopCR) return null;

    const changeRequest: ChangeRequest = {
      ...changeRequestCandidate,
      uuid: uuid(),
      comment: "",
      source: this.loginService.getUserData()?.company || 'unknown',
      user: this.loginService.getUserData()?.name || null
    }
    this.pushChangeRequest(changeRequest);

    this.snackbar.open('Change added to the changelog', 'close', { duration: 3000 })
    return changeRequest;
  }

  findSimilarChangeRequest(changeRequestCandidate: CandidateChangeRequest): ChangeRequest|undefined {
    const crSameEntitySameProperty =  this.changeRequests.find((cr) =>{
      const sameEntity = cr.entityId === changeRequestCandidate.entityId;
      const sameProperty = cr.property === changeRequestCandidate.property;
      return sameEntity && sameProperty;
    })
    return crSameEntitySameProperty
  }

  isNoopChangeRequest(changeRequestCandidate: CandidateChangeRequest, graphValue: any): boolean {
    if(changeRequestCandidate.newValue === graphValue){
      const similarCR = this.findSimilarChangeRequest(changeRequestCandidate);
      if(similarCR) this.discardChangeCandidate(similarCR.uuid);
      return true;
    }
    return false;
  }

  removeChangeRequest(uuid: string): void {
  }

  pushChangeRequest(changeRequest: ChangeRequest): void {
    this.changeRequests.push(changeRequest);
    this.changeRequests$.next(this.changeRequests);
  }

  discardChangeCandidate(uuid: string): void {
    this.changeRequests = [...this.changeRequests.filter((ce => ce.uuid !== uuid))]
    this.changeRequests$.next(this.changeRequests);
  }

  discardAllChangeCandidates(): void {
    this.changeRequests = [];
    this.changeRequests$.next(this.changeRequests);
  }


  commitAllChangeCandidates(comments: any): void {
    let i = 0;
    from(this.changeRequests)
    .pipe(
      map((cr) => ({...cr, comment: comments[i++]})),
      mergeMap((cr) => {
        if(cr.entityType === 'party')
          return this.apiSearchService.getArtistYSources(cr.entityId)
          .pipe(map((ysources) => ({...cr, ySources: ysources})))
        if(cr.entityType === 'recording')
          return this.apiSearchService.getRecordingYSources(cr.entityId)
          .pipe(map((ysources) => ({...cr, ySources: ysources})))
        else return throwError(() => new Error(`unknown entity type in change request[${cr.entityType}]`))
      }),
      mergeMap((cr) => this.ydbService.pushChangeRequest(cr))
    )
    .subscribe({
      next: (yChangeRequest) => {
        this.snackbar.open('Changes pushed successfully 🥳', 'close', { duration: 3000 })
        this.discardAllChangeCandidates();
      },
      error: (err) => console.error("error", err)
    });
  }

  openConfirmDialog(): void {
    this.dialog.open(DialogConfirmComponent, {
      panelClass: "dialog"
    });
  }

  enableEditMode(): void {
    this.editMode = true;
    this.editMode$.next(true);
  }

  disableEditMode(): void {
    this.editMode = false;
    this.editMode$.next(false);
  }

  isPropertyEditable(userData: any, entityType: string, property: string): void {
    return userData.rights.dataExplorer.enabled &&
      ((userData.rights.dataExplorer.edit as any)[entityType] == "all" ||
      (userData.rights.dataExplorer.edit as any)[entityType].includes(property))
  }

  getUserDataEdit(): any {
    return JSON.parse(localStorage.getItem("EDIT_RIGHTS") || "{}")
  }

  setUserDataEdit(userDataEdit: any): void {
    localStorage.setItem("EDIT_RIGHTS", JSON.stringify(userDataEdit));
  }

  // hasPendingChangeRequest(entityId: string, property: string): Observable<boolean> {
  //   return this.ydbService.getActiveChangeRequestsByEntityIdAndProperty$(entityId, property).pipe(
  //     map((changeRequests: ChangeRequest[]) => changeRequests.length > 0)
  //   );
  // }
}
