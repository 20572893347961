import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogFeedbackComponent } from "../../../dialog-feedback/dialog-feedback.component";

@Component({
  selector: 'table-empty',
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.css']
})

export class TableEmptyComponent {
  @Input('entity') entity!: string;

  constructor(private dialog: MatDialog){}

  openFeedbackDialog(): void {
    this.dialog.open(DialogFeedbackComponent, {
      panelClass: "dialog"
    });
  }
}
