<ng-container *ngIf="recordingStatus$|async as status">

  <ng-container *ngIf="status === 'error'">
    <ng-container *ngIf="recordingError$|async as error">
      <ng-container *ngIf="searchParams$|async as isrc">
        <h2 *ngIf="(error === ApiError.INVALID_ID || error === ApiError.NOT_FOUND)">
          We cannot find a recording with this identifier (isrc = {{isrc}}).
          Please verify the identifier in the search or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
        <h2 *ngIf="error === ApiError.SERVER_ERROR || error === ApiError.UNKNOWN">There has been a problem during the search.
          It may just be a blip, try again or <a href="javascript: return false" (click)="openFeedbackDialog()">report an error</a>.
        </h2>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="status === 'idle' || status === 'loading'">
    <skeleton-recording [isCrtcUser]="crtcService.isCrtcUser()"></skeleton-recording>
  </ng-container>

  <ng-container *ngIf="status === 'success'">
    <ng-container *ngIf="recording$|async as recording">
      <div class="entity-page">
        <div class="name-block">
          <h1>Recording:</h1>
          <div class="name">
            <h1>{{recording.title}}</h1>
            <em class="comments">{{recording.subtitle}}</em>
          </div>
          <button mat-icon-button class="link export" (click)="exportRecording(recording)" title="Download recording data">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button>
          <!-- <button mat-icon-button class="link export" (click)="export()" title="Download artist data">
            <mat-icon [inline]="true">arrow_circle_down</mat-icon>
          </button> -->
        </div>
        <div class="info">
          <div class="picture">
            <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null else cover" format="full" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
            <ng-template #cover>
              <img class="circle-mask circle-mask-large" src="{{'assets/images/recording_default.jpg'}}"/>
            </ng-template>
          </div>
          <div style="flex: 0 1 1em"></div>
          <div class="information">
            <h2>Information</h2>
            <div><span class="strong">Duration:</span> {{recording.duration | duration}}</div>
            <div><span class="strong">Year of Recording:</span> {{recording.year}}</div>
            <div><span class="strong">Country of Recording:</span> {{recording.country}}</div>
            <div *ngIf="mainArtists$|async as mainArtists"><span class="strong">Main artists:</span>
              <span *ngFor="let artist of mainArtists | sortBy:'asc':'name'">
                <party-link [party]="artist" style="margin:0 5px"></party-link>
              </span>
            </div>
            <div>&#9413; {{recording.pLine}}</div>
          </div>
          <div class="identifiers">
            <h2>Identifiers</h2>
            <div class="identifiers-list">
              <div class="identifier"><span class="strong">ISRC:</span> {{recording.isrc}}</div>
              <div class="identifier"><span class="strong">Spotify Track Id:</span>  <a href="https://open.spotify.com/track/{{recording.spotifyId}}" target="_blank">{{recording.spotifyId}}</a></div>
              <div class="identifier"><span class="strong">Apple Track Id:</span> {{recording.appleId}}</div>
            </div>
          </div>
          <div class="crtc" *ngIf="crtcService.isCrtcUser()">
            <h2>CRTC <cancon-flag [isCancon]="recording.crtcMaiplStatus?.isCanadianContent"></cancon-flag></h2>
            <div><div class="strong">MAIPL score: </div><maipl-score [score]="crtcMaiplScore$(recording)|async"></maipl-score></div>
            <div><div class="strong">Is Cancon: </div>{{recording.crtcMaiplStatus?.isCanadianContent ? 'Yes' : 'No'}}</div>
            <div>
              <div class="strong"> Language:  </div>
              <span *ngIf="recording.crtcPropertiesStatus.language as languageCode">
                {{getCrtcLanguageByCode(languageCode)?.name}}
              </span>
            </div>
            <div>
              <div class="strong"> Sub-Category: </div>
              <span *ngIf="recording.crtcPropertiesStatus.subCategory as subCategoryId">
                <ng-container *ngIf="getCrtcSubCategoryById(subCategoryId) as subCategory">
                  {{subCategory.subCategoryId}} - {{subCategory.label}} 
                </ng-container>
              </span>
            </div>
            <div>
              <div class="strong"> Is emerging: </div>
              <ng-container [ngSwitch]="recording.crtcPropertiesStatus.isEmerging">
                <span *ngSwitchCase="true">True</span>
                <span *ngSwitchCase="false">False</span>
                <span *ngSwitchDefault>Not set</span>
              </ng-container>
            </div>
            <div><button class="link" mat-button class="link" (click)="openCrtcDetailsDialog()">Edit</button></div>
            <div><changelog [entityId]="recording.isrc"></changelog></div>
          </div>
        </div>
        <div class="lists">
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="selectedTabChange($event)"
            [selectedIndex]="tabActive">
            <mat-tab label="Contributors">
              <div class="relationships" *ngIf="mainArtists$|async as mainArtists">
                <div class="title">Main Artists</div>
                <div class="grid grid-four-col">
                  <party-link [party]="mainArtist" *ngFor="let mainArtist of mainArtists | sortBy:'asc':'name'"></party-link>
                </div>
                <hr class="grid-col-span-2"/>
              </div>
              <div class="relationships" *ngIf="performers$|async as performers">
                <div class="title">Non Featured</div>
                <div class="grid grid-two-col">
                  <ng-container *ngFor="let performer of performers | sortBy:'asc':'name'">
                    <party-link [party]="performer"></party-link> ({{performer.role}})
                  </ng-container>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>Releases</div>
                <div class="data-count">
                  (<span *ngIf="releases$|async as releases">{{releases.length}}</span>)
                </div>
              </ng-template>
              <table-releases [releases$]="releases$" ></table-releases>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <div>Works</div>
                <div class="data-count" *ngIf="tableWorksComponent">({{tableWorksComponent.dataSource.data.length}})</div>
              </ng-template>
              <table-works [works$]="works$" [q2]="true" [recordingId]="recording.isrc"></table-works>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
