import { workFeature } from "./work.reducer"

export const {
  selectWork, selectWorks,
  selectWorkContributors, selectWorkContributorsTotal,
  selectRecordings, selectStatus, selectError
} = workFeature;

export const workQuery = {
  selectWork,
  selectWorks,
  selectWorkContributors,
  selectWorkContributorsTotal,
  selectRecordings,
  selectStatus,
  selectError
};
