<!-- (change)="editMaiplScore(crtcMaiplScore, 'music')" -->
<div class="maipl">
      <mat-list [formGroup]="formGroup">
        <mat-list-item>
            <mat-checkbox color="accent" formControlName="maiplScoreMusic"
            (click)="maiplScoreRulesService.overrideScore('music')">Music</mat-checkbox>
        </mat-list-item>
        <mat-list-item >
          <mat-checkbox  color="accent" formControlName="maiplScoreArtist"
          (click)="maiplScoreRulesService.overrideScore('artist')">Artist</mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox  color="accent" formControlName="maiplScoreInstr"
          (click)="maiplScoreRulesService.overrideScore('instrument')">Instrument</mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox color="accent" formControlName="maiplScorePerf"
          (click)="maiplScoreRulesService.overrideScore('performance')">Performance</mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox  color="accent" formControlName="maiplScoreLyrics"
          (click)="maiplScoreRulesService.overrideScore('lyrics')">Lyrics</mat-checkbox>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <mat-checkbox formControlName="isCancon" color="accent"
          (click)="maiplScoreRulesService.overrideIsCancon()">Is Cancon ?</mat-checkbox>
        </mat-list-item>
      </mat-list>
  </div>